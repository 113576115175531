import React, { Component } from 'react';
import PropTypes from 'prop-types';
import md5 from 'blueimp-md5';
import Immutable from 'immutable';

import './Avatar.css';

class Avatar extends Component {
  static propTypes = {
    children: PropTypes.node,
    profile: PropTypes.instanceOf(Immutable.Map),
    displaySubTitle: PropTypes.bool,
    subTitleHover: PropTypes.bool,
    size: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.string,
    backgroundColor: PropTypes.string
  };

  static defaultProps = {
    children: null,
    profile: null,
    displaySubTitle: false,
    subTitleHover: false,
    size: 24,
    title: '',
    color: '#ffffff',
    backgroundColor: '#D10303'
  };

  render() {
    const {
      profile, color, backgroundColor, size
    } = this.props;

    let { title } = this.props.title;
    let avatarUri = this.props.children;

    if (profile) {
      const name = profile.get('name');
      const email = profile.get('email');
      const avatarFileId = parseInt(profile.get('avatarFileId'), 10);
      const useGravatar = profile.get('avatarUseGravatar', false);

      title = name || email;
      avatarUri = title ? title.substring(0, 1).toUpperCase() : '?';

      if (useGravatar && email) {
        avatarUri = (<img src={`https://www.gravatar.com/avatar/${md5(email.toLowerCase())}?s=200`} alt="" />);
      } else if (avatarFileId) {
        avatarUri = `/download/?id=${avatarFileId}&maxWidth=100&width=100&height=100&zc=1`;
        avatarUri = (<img src={avatarUri} alt="" />);
      }
    }

    const style = {
      minWidth: size,
      maxWidth: size,
      minHeight: size,
      maxHeight: size,
      lineHeight: `${size}px`,
      color,
      backgroundColor
    };

    return (
      <div className="Avatar d-flex flex-column justify-content-center align-items-center">
        <div className="Avatar__Container" style={style}>
          { avatarUri }
        </div>
        { this.props.displaySubTitle !== false && (
          <div className={`Avatar__Title ${this.props.subTitleHover ? 'Avatar__Title--hover' : ''}`}>{title}</div>
        )}
      </div>
    );
  }
}

export default Avatar;
