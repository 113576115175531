import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Toggle.css';

class Toggle extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onToggle: PropTypes.func,
    toggled: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    label: null,
    onToggle: null,
    toggled: false,
  };

  state = {
    value: this.props.toggled,
  };

  onToggle = () => {
    this.setState({ value: !this.state.value }, () => {
      if (this.props.onToggle) {
        this.props.onToggle(this.state.value);
      }
    });
  };

  setInputRef = (e) => {
    this.input = e;
  };

  render() {
    const classNames = ['Toggle'];
    if (this.props.className) {
      classNames.push(this.props.className);
    }
    return (
      <div className={classNames.join(' ')}>
        <input
          type="checkbox"
          ref={this.setInputRef}
          className="Toggle__Input"
          value="1"
          name={this.props.name}
          defaultChecked={this.state.value}
        />
        <div
          className={`Toggle__Control${this.state.value ? ' Toggle__Control--active' : ''}`}
          onClick={this.onToggle}
          onKeyDown={null}
          role="button"
          tabIndex="-1"
        >
          <div className="Toggle__Control__Control">&nbsp;</div>
        </div>
        { this.props.label && (
          <div className="Toggle__Label" onClick={this.onToggle} onKeyDown={null} role="button" tabIndex="-1">
            {this.props.label}
          </div>
        )}
      </div>
    );
  }
}

export default Toggle;
