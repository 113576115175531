import 'es5-shim';
import 'babel-polyfill';

// import 'browser-update';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import registerServiceWorker from './registerServiceWorker';

import App from './App';
import ErrorBoundary from './component/ErrorBoundary/index';
import { initStore } from './store';

if (process.env.NODE_ENV !== 'production') {

  console.log(process.env);
}

const store = initStore();

const render = (cmp) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={store.history}>
        <ErrorBoundary>
          {cmp}
        </ErrorBoundary>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
};

render(<App />);

if (module.hot) {
  module.hot.accept('./App', () => {
    render(<App />);
  });
}

registerServiceWorker();

