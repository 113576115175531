import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import debounce from 'lodash/debounce';
import { createSelector } from 'reselect';

import moment from '../../inc/moment';
import withCustomer from '../../component/withCustomer';
import { setFilter } from '../../store/modules/product';
import Icon from '../../component/Icon';
import ProductBox from './catalog/ProductBox';

class History extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    columnCount: PropTypes.number.isRequired,
    history: PropTypes.instanceOf(Immutable.List).isRequired,
    filter: PropTypes.instanceOf(Immutable.Map).isRequired,
    setFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  static contextTypes = {
    customer: PropTypes.instanceOf(Immutable.Map)
  };

  state = {
    value: this.props.filter.get('query')
  };

  componentWillMount() {
    if (module.hot) {
      this.setState({ value: this.props.filter.get('query', '') });
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.filter.get('query') !== props.filter.get('query')) {
      this.setState({ value: props.filter.get('query') });
    }
  }

  onFilterChange = (e) => {
    e.persist();
    this.setState({ value: e.target.value });
    this.onDebouncedFilterChange(e);
  };

  onDebouncedFilterChange = debounce((e) => {
    this.props.setFilter({ query: e.target.value });
  }, 50);

  getRowHeight = ({ index }) => {
    const row = this.props.history.get(index);
    return 50 + (Math.ceil(row.get('items').size / this.props.columnCount) * 340);
  };

  getColumnStyle = () => ({
    width: 300,
    height: 340
  });

  renderRow = ({ index, /* isScrolling, */key, style }) => {
    const row = this.props.history.get(index);
    return (
      <div key={key} style={style} className="">
        <div className="d-flex flex-column pt-3">
          <div className="d-flex justify-content-center align-items-center">
            <strong>{ row.get('date') }</strong>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex flex-row flex-wrap ">
              { row.get('items').map(product => (
                <ProductBox
                  key={product.get('originalObjectId', product.get('svgImageId'))}
                  product={product}
                  productType={product.get('productType')}
                  style={this.getColumnStyle()}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderNoRows = () => (
    <div className="d-flex flex-1 justify-content-center">
      <Icon icon="exclamation-circle" className="mr-2" />
      { this.props.t('noresults') }
    </div>
  );

  render() {
    const { t } = this.props;
    const filterString = this.state.value;
    return (
      <div className="d-flex flex-1 flex-column">

        <div className="d-flex flex-column flex-1 flex-no-shrink">
          <div className="d-flex flex-column flex-no-shrink justify-content-between box-shadow-bottom p-2 " style={{ zIndex: 99 }}>
            <div className="d-flex flex-column flex-md-row flex-no-shrink align-items-center justify-content-between">
              <div>
                <h2 className="mr-3 mb-0">
                  { t('orderhistory') }
                </h2>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div className="visible-md">
                  <div className="SearchInput d-flex flex-row flex-1 align-items-center mr-3">
                    <Icon icon="search" className={`SearchInput__Icon mr-3 ${filterString ? ' SearchInput__Icon--active' : ''} mr-3`} />
                    <input
                      type="text"
                      placeholder={`${t('search')}...`}
                      onChange={this.onFilterChange}
                      value={filterString}
                      className="SearchInput__Input form-control"
                      style={{ width: 200 }}
                    />
                    { filterString && (
                      <Icon icon="times" className="SearchInput__Clear" onClick={this.onFilterClear} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-1 flex-column Customer__Table__Content">
            <div className="d-flex flex-1 flex-column overflow-hidden" style={{ flex: '1 1 auto' }}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    width={width}
                    rowCount={this.props.history.size}
                    rowRenderer={this.renderRow}
                    noContentRenderer={this.renderNoRows}
                    rowHeight={this.getRowHeight}
                    useDynamicRowHeight
                  />
                )}
              </AutoSizer>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const selectColumnCount = createSelector([
  state => state.app.get('windowWidth')
], (width) => {
  if (width >= 1600) {
    return 6;
  }
  if (width >= 1280) {
    return 4;
  }
  if (width >= 1000) {
    return 3;
  }
  if (width >= 640) {
    return 2;
  }
  return 1;
});

const selectHistory = createSelector([
  customer => customer.get('history'),
  (customer, product) => product.get('data'),
  (customer, product) => product.get('filter'),
], (history, productTypes, filter) => {
  if (!history) {
    return Immutable.Map();
  }

  const re = new RegExp(`.*${filter.get('query', '')}.*`, 'i');
  return history.filter(p => re.test(p.get('title'))).map((item) => {
    const productType = productTypes.filter(p => p.get('id') === item.get('retailProductTypeId')).first();
    return item.set('productType', productType);
  }).reduce((c, item) => {
    const date = moment(item.get('createDatetime')).format('DD-MM-YYYY');
    const index = c.findIndex(i => i.get('date') === date);
    if (index === -1) {
      return c.push(Immutable.Map({ date, items: Immutable.List().push(item) }));
    }
    return c.setIn([index, 'items'], c.getIn([index, 'items']).push(item));
  }, Immutable.List());
});

export default translate()(withCustomer(connect((state, props) => ({
  columnCount: selectColumnCount(state),
  filter: state.product.get('filter'),
  history: selectHistory(props.customer, state.product)
}), {
  setFilter
})(History)));
