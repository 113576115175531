import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { push as routePush } from 'react-router-redux';
import Link from 'react-router-dom/Link';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import debounce from 'lodash/debounce';
import { createSelector } from 'reselect';

import './Overview.css';

import { setFilter } from '../../store/modules/customer';
import Icon from '../../component/Icon';
import Toggle from '../../component/Toggle';

class Overview extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    routePush: PropTypes.func.isRequired,
    customers: PropTypes.instanceOf(Immutable.List).isRequired,
    filter: PropTypes.instanceOf(Immutable.Map).isRequired,
    setFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  state = {
    value: this.props.filter.get('query')
  };

  componentWillMount() {
    this.setState({ value: this.props.filter.get('query', '') });
  }

  componentWillReceiveProps(props) {
    if (this.props.filter.get('query') !== props.filter.get('query')) {
      this.setState({ value: props.filter.get('query') });
    }
  }

  onFilterClear = () => {
    this.setState({ value: '' });
    this.props.setFilter({ query: '' });
  };

  onFilterToggleOnlyWithHistory = (active) => {
    this.props.setFilter(this.props.filter.set('onlyWithHistory', active));
  };

  onNewCustomer = () => {
    this.props.routePush('/customer/create');
  };

  onFilterChange = (e) => {
    e.persist();
    this.setState({ value: e.target.value });
    this.onDebouncedFilterChange(e);
  };

  onDebouncedFilterChange = debounce((e) => {
    this.props.setFilter({ query: e.target.value });
  }, 50);

  // getDatum = index => this.props.customers.get(index % this.props.customers.size);

  getRowHeight = () => 70; // this.getDatum(index).size;

  renderCustomerRow = ({ index, /* isScrolling, */key, style }) => {
    const { t } = this.props;
    const customer = this.props.customers.get(index % this.props.customers.size);
    const customerId = customer.get('id');
    const title = [
      [
        customer.get('companyTitle'),
        [
          customer.get('firstName'),
          customer.get('middleName'),
          customer.get('lastName'),
        ].filter(v => !!v).join(' ')
      ].join(' - '),
    ].filter(v => !!v).join(' ');

    const location = [
      customer.get('invoiceAddressStreet'),
      `${customer.get('invoiceAddressStreetNumber')},`,
      customer.get('invoiceAddressZipcode'),
      `${customer.get('invoiceAddressCity')},`,
      t(`country${customer.get('invoiceAddressCountryCode').toLowerCase()}`),
    ].join(' ');
    return (
      <div key={key} style={style} className="Customer__Table__Content__Row d-flex flex-1 flex-row flex-no-shrink p-2">

        <div className="d-flex flex-column flex-1">
          <div className="d-flex flex-column flex-1 flex-sm-row ">
            <div className="d-flex flex-column justify-content-center mr-2">
              { customerId }
            </div>

            <div className="d-flex flex-column flex-1 justify-content-center">
              <strong className="color-primary">
                { title }
              </strong>
            </div>
          </div>

          <div className="d-flex flex-column flex-1 justify-content-center mr-3">
            { location }
          </div>

        </div>

        <div className="d-flex flex-row justify-content-end align-items-center mr-3">
          {
            customer.get('history').size > 0 && (
              <Link to={`/customer/${customer.get('id')}/history`} className="btn btn-default d-flex flex-row">
                <Icon icon="list-alt" className="mr-2" />
                <span className="d-none d-sm-block">
                  { t('orderhistory') }
                </span>
              </Link>
            )
          }
          <Link to={`/customer/${customerId}/edit`} className="btn btn-default d-flex flex-row">
            <Icon icon="edit" className="mr-2" />
            <span className="d-none d-sm-block">
              {t('edit')}
            </span>
          </Link>
          <Link to={`/customer/${customerId}/catalog`} className="btn btn-primary d-flex flex-row">
            <Icon icon="shopping-cart" className="mr-2" />
            <span className="d-none d-sm-block">
              {t('catalog')}
            </span>
          </Link>
        </div>
      </div>
    );
  };

  renderNoRows = () => (
    <div className="d-flex flex-1 justify-content-center">
      <Icon icon="exclamation-circle" className="mr-2" />
      { this.props.t('noresults') }
    </div>
  );

  render() {
    const { t } = this.props;
    const filterString = this.state.value;
    const isDisplayingOnlyWithHistory = this.props.filter.get('onlyWithHistory', false);

    return (
      <div className="d-flex flex-1 flex-column Customer__Table">
        <div className="d-flex flex-row p-3 justify-content-between align-items-center Customer__Table__Toolbar box-shadow-bottom">
          <div className="SearchInput d-flex flex-row flex-1 align-items-center mr-3">
            <Icon icon="search" className={`SearchInput__Icon mr-3 ${filterString ? ' SearchInput__Icon--active' : ''} mr-3`} />
            <input
              type="text"
              placeholder={`${t('search')}...`}
              onChange={this.onFilterChange}
              value={filterString}
              className="SearchInput__Input flex-1 form-control"
              style={{ width: 200 }}
            />
            { filterString && (
              <Icon
                icon="times"
                className={`SearchInput__Clear ${filterString ? ' SearchInput__Clear--active' : ''}`}
                onClick={this.onFilterClear}
              />
            )}
          </div>

          <div className="d-flex flex-row">
            <Toggle
              className="mr-3"
              name="onlyWithHistory"
              label={t('onlywithhistory')}
              onToggle={this.onFilterToggleOnlyWithHistory}
              toggled={isDisplayingOnlyWithHistory}
            />

            <button className="btn btn-primary" onClick={this.onNewCustomer}>
              <Icon icon="user-plus" />
              &nbsp;
              {t('New customer')}
            </button>
          </div>

        </div>

        <div className="d-flex flex-1 flex-column Customer__Table__Content">
          <div className="d-flex flex-1 flex-column overflow-hidden" style={{ flex: '1 1 auto' }}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  width={width}
                  rowCount={this.props.customers.size}
                  rowRenderer={this.renderCustomerRow}
                  noContentRenderer={this.renderNoRows}
                  rowHeight={this.getRowHeight}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    );
  }
}

const selectBySearch = createSelector([
  customers => customers,
  (customers, filter) => filter,
], (customers, filter) => {
  const query = filter.get('query');
  if (!query) {
    return customers;
  }
  const re = new RegExp(`.*${query}.*`, 'i');
  return customers.filter(customer =>
    re.test(customer.get('id')) ||
    re.test(customer.get('companyTitle')) ||
    re.test(customer.get('firstName')) ||
    re.test(customer.get('lastName')) ||
    re.test(customer.get('city')) ||
    re.test(customer.get('zipcode')));
});

const selectWithHistory = createSelector([
  customers => customers,
  (customers, filter) => filter,
], (customers, filter) => {
  if (customers.size === 0 || !filter.get('onlyWithHistory', false)) {
    return customers;
  }
  return customers.filter(c => c.get('history').size > 0);
});

const selectCustomers = createSelector([
  state => state.customer.get('data'),
  state => state.customer.get('filter'),
], (customers, filter) => selectWithHistory(selectBySearch(customers, filter), filter));

export default translate()(connect(state => ({
  profile: state.auth.get('profile'),
  customers: selectCustomers(state),
  filter: state.customer.get('filter'),
}), {
  routePush,
  setFilter
})(Overview));
