import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';

import './Logo.css';

class Logo extends Component {
  static propTypes = {
    lang: PropTypes.string.isRequired,
  };

  static defaultProps = {
  };

  render() {
    const { lang } = this.props;
    return (
      <div>
        {
          lang === 'nl' && (
            <Link to="/" className="Logo">
              Mijn<span>Hartenwens</span>.nl
            </Link>
          )
        }
        {
          lang === 'de' && (
            <Link to="/" className="Logo">
              Mein<span>Herzenwunsch</span>.eu
            </Link>
          )
        }
      </div>
    );
  }
}

export default connect(state => ({
  lang: state.app.getIn(['env', 'data', 'siteLanguage'], 'nl')
}))(Logo);
