/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import withRouter from 'react-router/withRouter';
import Switch from 'react-router/Switch';
import Route from 'react-router/Route';
import Notifications from 'react-notification-system-redux';

import i18n from './inc/i18n';

import './layout/style/index.css';

import AuthedRoute from './component/AuthedRoute';
import AppLayoutContainer from './layout/AppLayout';
import { initApp } from './store/modules/app';
import AuthLoginContainer from './route/auth/Login';
import AuthResetContainer from './route/auth/Reset';
import AuthLogoutContainer from './route/auth/Logout';
import Loader from './component/Loader';

class AppRoot extends Component {
  static propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    isAppInitialized: PropTypes.bool.isRequired,
    initApp: PropTypes.func.isRequired,
  };

  componentWillMount() {
    if (!this.props.isAppInitialized) {
      this.props.initApp();
    }
  }

  render() {
    if (!this.props.isAppInitialized) {
      return (
        <div className="d-flex flex-1 flex-column justify-content-center align-items-center">
          <Loader />
        </div>
      );
    }
    return (
      <div className="d-flex flex-1 flex-column">
        <Notifications notifications={this.props.notifications} />
        <I18nextProvider i18n={i18n}>
          <Switch>
            <Route path="/auth/login" exact component={AuthLoginContainer} />
            <Route path="/auth/reset" exact component={AuthResetContainer} />
            <Route path="/auth/logout" exact component={AuthLogoutContainer} />
            <AuthedRoute path="/" component={AppLayoutContainer} />
          </Switch>
        </I18nextProvider>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  notifications: state.notifications,
  isAppInitialized: state.app.get('hasInitialized', false)
}), {
  initApp
})(AppRoot));
