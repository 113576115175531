import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router/withRouter';
import Route from 'react-router/Route';
// import Switch from 'react-router/Switch';
// import Immutable from 'immutable';
import ReduxToastr from 'react-redux-toastr';

import HeaderContainer from './component/Header';
import InitialSyncContainer from './component/InitialSync';
import RouteContainer from '../route';
// import FooterContainer from './component/Footer';

import { startDataSync, onWindowResize } from '../store/modules/app';

const confirmOptions = {
  okText: 'Ok',
  cancelText: 'Annuleren',
  transitionIn: 'fadeIn',
  transitionOut: 'fadeOut'
};

class AppLayoutContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    startDataSync: PropTypes.func.isRequired,
    hasSyncedAtLeastOnce: PropTypes.bool.isRequired,
    onWindowResize: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: null
  };

  componentWillMount() {
    window.addEventListener('resize', this.onResize);
    this.props.startDataSync();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.props.onWindowResize();
  };

  render() {
    if (!this.props.hasSyncedAtLeastOnce) {
      return (
        <InitialSyncContainer />
      );
    }
    return (
      <div className="d-flex flex-column flex-1" style={{ overflow: 'hidden' }}>
        <ReduxToastr
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          confirmOptions={confirmOptions}
        />
        <div className="d-flex flex-column flex-1">
          <Route path="*" component={HeaderContainer} />
          <div className="d-flex flex-1 flex-column flex-overflow-scroll">
            { this.props.children || <RouteContainer /> }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  hasSyncedAtLeastOnce: state.app.get('hasSynced')
}), {
  startDataSync,
  onWindowResize,
})(AppLayoutContainer));
