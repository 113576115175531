import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Immutable from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { createSelector } from 'reselect';
import withRouter from 'react-router/withRouter';
import { push as routePush } from 'react-router-redux';
import { reduxForm, formPropTypes } from 'redux-form';
// import isEqual from 'lodash/isEqual';
import Ajv from 'ajv';

import { setActiveLanguage } from '../../store/modules/app';
import { updateProfile } from '../../store/modules/auth';
import Icon from '../../component/Icon';
import SchemaInput from '../../component/Form/SchemaInput';
import { ajvErrorMapperCreator, reduxFormOnSubmitFailScrollToFirstError } from '../../inc/formUtil';
import OfflineChangesWarning from '../../component/OfflineChangesWarning';

const __DEV__ = process.env.NODE_ENV === 'development';

class Edit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired,
    setActiveLanguage: PropTypes.func.isRequired,
    languageShort: PropTypes.string.isRequired,
    routePush: PropTypes.func.isRequired,
    ...formPropTypes
  };

  static defaultProps = {
  };

  componentWillReceiveProps(props) {
    if (props.languageShort !== this.props.languageShort) {
      this.props.setActiveLanguage(props.languageShort.toLowerCase());
    }
  }

  // shouldComponentUpdate(props) {
  //   return !isEqual(this.props, props);
  // }

  onSubmit = data => this.props.updateProfile(data);

  render() {
    const { t, schema } = this.props;

    if (!schema) {
      return null;
    }

    return (
      <div className="d-flex flex-column">

        <div className="flex-overflow-scroll">

          <div className="container mt-4 mb-4 p-4">
            <SchemaInput schema={schema} type="hidden" name="id" />

            <div className="row">
              <h3 className="col-12">{t('Edit profile')}</h3>
            </div>

            <div className="row">

              <div className="col-12 col-sm-2">
                <SchemaInput schema={schema} name="gender" placeholder={t('gendersalutationlabel')} label />
              </div>

              <div className="col-12 col-sm-3">
                <SchemaInput schema={schema} name="firstName" placeholder={t('personfirstname')} label />
              </div>

              <div className="col-12 col-sm-3">
                <SchemaInput schema={schema} name="middleName" placeholder={t('personmiddlename')} label />
              </div>

              <div className="col-12 col-sm-4">
                <SchemaInput schema={schema} name="lastName" placeholder={t('personlastname')} label />
              </div>
            </div>

            <div className="row mt-3">

              <div className="col-12 col-sm-4">
                <SchemaInput schema={schema} name="phone" placeholder={t('phone')} label />
              </div>

              <div className="col-12 col-sm-4">
                <SchemaInput schema={schema} name="email" placeholder={t('email')} label />
              </div>

              <div className="col-12 col-sm-4">
                <SchemaInput
                  schema={schema}
                  name="languageShort"
                  placeholder={t('language')}
                  label
                />
              </div>

            </div>

            <div className="row">
              <h4 className="col-12 mt-2">{t('addresspostal')}</h4>
            </div>
            <div className="row">

              <div className="col-12 col-sm-6 col-lg-3">
                <SchemaInput schema={schema} name="address.street" placeholder={t('street')} label />
              </div>

              <div className="col-12 col-sm-6 col-lg-2">
                <SchemaInput
                  schema={schema}
                  name="address.streetNumber"
                  placeholder={t('streetnumber')}
                  label
                />
              </div>

              <div className="col-12 col-sm-4 col-lg-2">
                <SchemaInput schema={schema} name="address.zipcode" placeholder={t('zipcode')} label />
              </div>

              <div className="col-12 col-sm-4 col-lg-3">
                <SchemaInput schema={schema} name="address.city" placeholder={t('city')} label />
              </div>

              <div className="col-12 col-sm-4 col-lg-2">
                <SchemaInput
                  schema={schema}
                  name="address.countryCode"
                  placeholder={t('country')}
                  label
                />
              </div>
            </div>

            <OfflineChangesWarning />

            <div className="row mt-2">
              <div className="col-12 d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-default mr-2"
                  onClick={this.props.reset}
                  disabled={this.props.pristine}
                >
                  <Icon icon="undo" className="mr-2" />
                  {t('reset')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={this.props.handleSubmit(this.onSubmit)}
                  disabled={this.props.pristine || this.props.submitting}
                >
                  { this.props.submitting ? (
                    <Icon icon="circle-notch" spin fixedWidth className="mr-2" />
                  ) : (
                    <Icon icon="save" fixedWidth className="mr-2" />
                  ) }
                  {t('savechanges')}
                </button>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

const isValidZipCodeNL = zipcode => zipcode && /^[1-9][0-9]{3}\s*[a-z]{2}$/i.test(zipcode.trim());

/**
 * @param data
 * @param props
 *
 * @returns {{}}
 */
let ajv = null;
const validateForm = (data, props) => {
  if (!ajv) {
    if (__DEV__) {

      console.log('[ajv] compiling api schema');
    }
    ajv = new Ajv({ removeAdditional: true, allErrors: true, verbose: true });
    ajv.compile(props.schema.set('$id', 'Profile').toJS());
    // this.ajv = ajv;
  }
  if (__DEV__) {

    console.log('data', data);
  }
  const isValid = ajv.validate('Profile', data);
  if (__DEV__) {

    console.log('isValid', isValid);
  }

  const errors = {};

  if (!isValid) {
    if (__DEV__) {

      console.log(ajv.errors);
    }
    ajv.errors.map(ajvErrorMapperCreator(errors, props.t));
  }

  // todo add to ajv validator
  if (data.addressCountryCode === 'NL' && !isValidZipCodeNL(data.addressZipcode)) {
    errors.addressZipcode = props.t('invalidnlzipcodeformat');
  }
  return errors;
};

const selectSchema = createSelector([
  state => state.app.getIn(['schemas', 'data', 'Profile']),
  (state, props) => props.t,
], (schema, t) => {
  if (!schema) {
    return null;
  }
  return schema
    .setIn(
      ['properties', 'address', 'properties', 'countryCode', 'enum_titles'],
      schema.getIn(['properties', 'address', 'properties', 'countryCode', 'enum']).map(v => t(`country${v.toLowerCase()}`))
    )
    .setIn(
      ['properties', 'languageShort', 'enum_titles'],
      schema.getIn(['properties', 'languageShort', 'enum']).map(v => t(`language${v.toLowerCase()}`))
    )
    .setIn(
      ['properties', 'gender', 'enum_titles'],
      schema.getIn(['properties', 'gender', 'enum']).map(v => t(`gendersalutation${v.toLowerCase()}`))
    );
});

export default translate()(withRouter(connect((state, props) => {
  const profile = state.auth.get('profile');
  return {
    isOffline: !state.app.get('online'),
    languageShort: profile.get('languageShort', 'NL'),
    schema: selectSchema(state, props),
    initialValues: profile.toJS(),
  };
}, {
  routePush,
  setActiveLanguage,
  updateProfile
})(reduxForm({
  form: 'profile',
  onSubmitFail: reduxFormOnSubmitFailScrollToFirstError,
  enableReinitialize: true,
  validate: validateForm
})(Edit))));
