import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import Icon from '../../component/Icon';
import { toggleModal } from '../../store/modules/app';
import Modal from '../../component/Modal';
import SyncStatusStatus from './SyncStatusStatus';

import './SyncStatus.css';

class SyncStatus extends Component {
  static propTypes = {
    isSyncing: PropTypes.bool.isRequired,
    offline: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool,
  };

  static defaultProps = {
    isModalOpen: false,
  };

  onToggleModal = () => {
    this.props.toggleModal('syncStatus');
  };

  render() {
    const hasCacheSupport = !!window.caches;
    const { t } = this.props;
    return (
      <div className="mr-3 SyncStatus">
        { this.props.isSyncing && (
          <div className="SyncStatus__Spinner">
            <Icon icon="sync-alt" spin onClick={this.onToggleModal} />
          </div>
        ) }
        { this.props.offline && (
          <Icon icon="wifi-slash" className="mr-2" />
        ) }
        { !hasCacheSupport && (
          <button type="button" className="btn btn-primary" onClick={this.onToggleModal}>
            <Icon icon="exclamation-triangle" className="mr-2" />
            {t('offlinemode_unavailable')}
          </button>
        )}
        { this.props.offline && hasCacheSupport && (
          <button type="button" className="btn btn-primary" onClick={this.onToggleModal}>
            <Icon icon="exclamation-triangle" className="mr-2" />
            {t('offlinemode_active')}
          </button>
        ) }
        <Modal
          ariaHideApp={false}
          onCancel={this.onToggleModal}
          title={this.props.offline ? t('Your are offline') : t('Synchronisation status')}
          isOpen={this.props.isModalOpen}
        >
          <div>
            { this.props.offline && hasCacheSupport && (
              <div>
                {t('offlinemode_sync_later')}
              </div>
            )}
            { !hasCacheSupport && (
              <div>
                {t('offlinemode_browser_no_offline')}
              </div>
            )}
            {
              !this.props.offline && hasCacheSupport && (
                <SyncStatusStatus />
              )
            }
            <div className="d-flex justify-content-end mt-2 ">
              <button type="button" className="btn btn-primary" onClick={this.onToggleModal}>
                Ok
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default translate()(connect(state => ({
  offline: !state.app.get('online'),
  isSyncing: state.app.get('isSyncing', false),
  syncStatus: state.app.get('syncStatus'),
  syncError: state.app.get('syncError', null),
  isModalOpen: state.app.getIn(['modal', 'syncStatus'], false),
}), {
  toggleModal
})(SyncStatus));
