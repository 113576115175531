import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import Icon from './Icon';

class OfflineChangesWarning extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    text: PropTypes.string,
    isOffline: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    text: null,
  };

  render() {
    if (!this.props.isOffline) {
      return null;
    }
    const { t } = this.props;
    return (
      <div className="alert alert-warning d-flex justify-content-center mt-2">
        <Icon icon="exclamation-circle" className="mr-3" />
        <div>
          <span>{ t('You are') }&nbsp;</span>
          <strong>{t('offline')}</strong>,&nbsp;
          { t(this.props.text || 'offlinesavedescription') }.
        </div>
      </div>
    );
  }
}

export default translate()(connect(state => ({
  isOffline: !state.app.get('online'),
}))(OfflineChangesWarning));
