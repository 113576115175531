import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Link from 'react-router-dom/Link';

import Icon from '../../component/Icon';

class Overview extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;
    return (
      <div className="d-flex flex-1 flex-column">
        <div className="d-flex flex-column flex-1 justify-content-center align-items-center">

          <div className="d-flex flex-row justify-content-between flex-wrap">

            <Link to="/customer" className="d-flex flex-1 flex-no-shrink flex-column align-items-center">
              <Icon icon="search" style={{ fontSize: '4em' }} />
              <div className="d-flex flex-no-shrink flex-column justify-content-center align-items-center text-center">
                {t('Find customer')}
              </div>
            </Link>

            <Link to="/customer/create" className="d-flex flex-1 flex-no-shrink flex-column align-items-center">
              <Icon icon="user-plus" style={{ fontSize: '4em' }} />
              <div className="d-flex flex-1 flex-no-shrink flex-column justify-content-center align-items-center text-center">
                {t('Add new customer')}
              </div>
            </Link>

            <Link to="/profile" className="d-flex flex-1 flex-no-shrink flex-column align-items-center">
              <Icon icon="user" style={{ fontSize: '4em' }} />
              <div className="d-flex flex-1 flex-no-shrink flex-column justify-content-center align-items-center text-center">
                {t('Edit profile')}
              </div>
            </Link>

          </div>
        </div>
      </div>
    );
  }
}

export default translate()(Overview);
