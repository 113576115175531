import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withRouter from 'react-router/withRouter';
import Link from 'react-router-dom/Link';
import { push as routePush } from 'react-router-redux';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';

import './SelectedCustomerContainer.css';

import Icon from '../../component/Icon';
import CartWidget from './catalog/Cart';
import CustomerEditContainer from './Edit';
import CustomerHistoryContainer from './History';
// import CustomerCatalogContainer from './Catalog';
import CustomerCatalogContainer from './catalog/Products';
import CustomerCartContainer from './Cart';

const selectCustomerTitle = createSelector([
  customer => customer
], (customer) => {
  if (!customer.get('id')) {
    return '';
  }
  return [
    customer.get('companyTitle'),
    '-',
    [
      customer.get('firstName'),
      customer.get('middleName'),
      customer.get('lastName'),
    ].join(' '),
    '-',
    customer.get('deliveryAddressSameAsinvoiceAddress')
      ? customer.get('invoiceAddressCountryCode') : customer.get('deliveryAddressCountryCode')
  ].join(' ');
});

class SelectedCustomerContainer extends Component {
  static propTypes = {
    isCartView: PropTypes.bool,
    isProfileView: PropTypes.bool,
    isHistoryView: PropTypes.bool,
    isCatalogView: PropTypes.bool,
    language: PropTypes.string.isRequired,
    // themaId: PropTypes.number.isRequired,
    // siteCountryCodeId: PropTypes.number.isRequired,
    customer: PropTypes.instanceOf(Immutable.Map),
    routePush: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isProfileView: false,
    isHistoryView: false,
    isCartView: false,
    isCatalogView: false,
    customer: null
  };

  static childContextTypes = {
    language: PropTypes.string.isRequired,
    customer: PropTypes.instanceOf(Immutable.Map),
    customerId: PropTypes.number
  };

  getChildContext() {
    return {
      language: this.props.language,
      customer: this.props.customer,
      customerId: (this.props.customer && this.props.customer.get('id', 0)) || 0
    };
  }

  onCloseCustomer = () => {
    this.props.routePush('/customer');
  };

  render() {
    const {
      t, customer, isProfileView, isHistoryView
    } = this.props;

    const hasCartWidget = customer && !this.props.isCartView;
    const hasGotoCatalog = customer && !this.props.isCartView && !this.props.isCatalogView;
    return (
      <div className="d-flex flex-1 flex-no-shrink flex-column">
        <div className="d-flex flex-no-shrink flex-row align-items-center justify-content-between SelectedCustomerContainer__CustomerHeader ">
          <div>
            { t('customer') }: { !customer ? t('new') : selectCustomerTitle(customer) }
            &nbsp;
            <Icon icon="times" onClick={this.onCloseCustomer} />
          </div>
          <div className="d-flex flex-no-shrink flex-row child-mr-2-omega">
            { !isProfileView && customer && (
              <div className="d-flex flex-row">
                <Link to={`/customer/${customer.get('id')}/edit`} className="btn btn-primary">
                  <Icon icon="user" className="mr-2" />
                  {t('Customer profile')}
                </Link>
              </div>
            )}
            { !isHistoryView && customer && customer.get('history').size > 0 && (
              <div className="d-flex flex-row">
                <Link to={`/customer/${customer.get('id')}/history`} className="btn btn-primary">
                  <Icon icon="list-alt" className="mr-2" />
                  { t('orderhistory') }
                </Link>
              </div>
            )}
            { hasCartWidget && (
              <CartWidget customerId={customer.get('id')} />
            ) }
            { hasGotoCatalog && (
              <div className="d-flex flex-row">
                <Link to={`/customer/${customer.get('id')}/catalog`} className="btn btn-primary">
                  <Icon icon="hand-point-right" className="mr-2" />
                  {t('catalog')}
                </Link>
              </div>
            ) }
          </div>
        </div>
        <Switch>
          <Route path="/customer/create" exact component={CustomerEditContainer} />
          <Route path="/customer/:customerId/edit" exact component={CustomerEditContainer} />
          <Route path="/customer/:customerId/catalog" exact component={CustomerCatalogContainer} />
          <Route path="/customer/:customerId/catalog/:productTypeId" component={CustomerCatalogContainer} />
          <Route path="/customer/:customerId/history" component={CustomerHistoryContainer} />
          <Route path="/customer/:customerId/cart" component={CustomerCartContainer} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(translate()(connect((state, props) => ({
  isProfileView: props.location && props.location.pathname
    && /^\/customer\/\d+\/edit\/?$/.test(props.location.pathname),
  isHistoryView: props.location && props.location.pathname
    && /^\/customer\/\d+\/history\/?$/.test(props.location.pathname),
  isCartView: props.location && props.location.pathname
    && /^\/customer\/\d+\/cart\/?$/.test(props.location.pathname),
  isCatalogView: props.location && props.location.pathname
    && /^\/customer\/\d+\/catalog\/?/.test(props.location.pathname),
  language: state.app.getIn(['env', 'language'], 'nl'),
  themaId: state.app.getIn(['env', 'language'], 'nl') === 'de' ? 58569 : 33456,
  siteCountryCodeId: state.app.getIn(['env', 'language'], 'nl') === 'de' ? 1 : 0,
  profile: state.auth.get('profile'),
  customer: state.customer.get('data')
    .find(customer => customer.get('id') === parseInt(props.match.params.customerId, 10))
}), {
  routePush
})(SelectedCustomerContainer)));
