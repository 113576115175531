import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './DropDownMenu.css';

class DropDownMenu extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    activeMenuItem: PropTypes.node.isRequired,
    popoutBelow: PropTypes.bool,
    keepOpen: PropTypes.bool,
    isOpen: PropTypes.bool,
    onAfterOpen: PropTypes.func,
    onAfterClose: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    children: null,
    onAfterOpen: null,
    onAfterClose: null,
    popoutBelow: false,
    keepOpen: false,
    isOpen: false,
  };

  static childContextTypes = {
    onHideMenu: PropTypes.func
  };

  state = {
    expanded: false
  };

  getChildContext() {
    return {
      onHideMenu: this.onHide
    };
  }

  componentWillMount() {
    if (!this.props.keepOpen) {
      window.addEventListener('click', this.onWindowClick);
    }
  }

  componentWillUnmount() {
    if (!this.props.keepOpen) {
      window.removeEventListener('click', this.onWindowClick);
    }
  }

  onToggleShow = () => {
    this.setState({ expanded: !this.state.expanded }, () => {
      if (this.state.expanded) {
        this.onAfterOpen();
      } else {
        this.onAfterClose();
      }
    });
  };

  onHide = () => {
    this.setState({ expanded: false });
    this.onAfterClose();
  };

  onWindowClick = (e) => {
    if (!this.el.contains(e.target) || e.target.nodeName === 'A') {
      this.onHide(e);
    }
  };

  onAfterOpen = () => {
    if (this.props.onAfterOpen) {
      this.props.onAfterOpen();
    }
  };

  onAfterClose = () => {
    if (this.props.onAfterClose) {
      this.props.onAfterClose();
    }
  };

  setRef = (el) => {
    this.el = el;
  };

  render() {
    const isOpen = (this.props.keepOpen && this.props.isOpen) || this.state.expanded;
    return (
      <div className={`DropDownMenu${this.props.className ? ` ${this.props.className}` : ''}`} ref={this.setRef}>
        <div className="DropDownMenu__ActiveItem" onClick={this.onToggleShow} role="button" tabIndex="-1" onKeyDown={null}>
          { this.props.activeMenuItem }
        </div>
        { this.props.children && isOpen && (
          <div className={`DropDownMenu__MenuItems${this.props.popoutBelow ? ' DropDownMenu__MenuItems--below' : ''}`}>
            { this.props.children }
          </div>
        )}
      </div>
    );
  }
}

export default DropDownMenu;
