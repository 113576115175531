/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { createSelector } from 'reselect';
import withRouter from 'react-router/withRouter';
import { push as routePush } from 'react-router-redux';
import { reduxForm, formPropTypes, formValueSelector } from 'redux-form';
import Ajv from 'ajv';

import Input from '../../component/Form/Input';
import { update as updateCustomer } from '../../store/modules/customer';
import Icon from '../../component/Icon';
import SchemaInput from '../../component/Form/SchemaInput';
import { ajvErrorMapperCreator, reduxFormOnSubmitFailScrollToFirstError } from '../../inc/formUtil';
import OfflineChangesWarning from '../../component/OfflineChangesWarning';

const __DEV__ = process.env.NODE_ENV === 'development';

class Edit extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isNew: PropTypes.bool.isRequired,
    isOffline: PropTypes.bool.isRequired,
    updateCustomer: PropTypes.func.isRequired,
    routePush: PropTypes.func.isRequired,
    customer: PropTypes.instanceOf(Immutable.Map).isRequired,
    deliveryAddressSameAsinvoiceAddress: PropTypes.bool,
    ...formPropTypes
  };

  static defaultProps = {
    deliveryAddressSameAsinvoiceAddress: true
  };

  onSubmit = data => this.props.updateCustomer(data).then((newData) => {
    if (!data.id && newData.id) {
      this.props.routePush(`/customer/${newData.id}/edit`);
    }
    return newData;
  });

  onSubmitThenCatalog = data => this.onSubmit(data).then((newData) => {
    this.props.routePush(`/customer/${newData.id}/catalog`);
  });

  onCloseCustomer = () => {
    this.props.routePush('/customer');
  };

  render() {
    const { t, customer, schema } = this.props;
    if (!customer) {
      return null;
    }

    return (
      <div className="d-flex flex-column">

        <div className="flex-overflow-scroll">

          <div className="container mt-4 mb-4 p-4">
            <SchemaInput schema={schema} type="hidden" name="id" />

            <div className="row">
              <h3 className="col-12">{t('companyedit')}</h3>
            </div>

            <div className="row">

              <div className="col-12 col-sm-6">
                <SchemaInput schema={schema} name="companyTitle" placeholder={t('personcompanyname')} label />
              </div>

              <div className="col-12 col-sm-6">
                <SchemaInput schema={schema} name="chamberOfCommerceNumber" placeholder={t('chamberofcommercenumber')} label />
              </div>

              <div className="col-12 col-sm-6">
                <SchemaInput schema={schema} name="phoneNumber" placeholder={t('phone')} label />
              </div>

              <div className="col-12 col-sm-6">
                <SchemaInput schema={schema} name="taxNumber" placeholder={t('taxnumber')} label />
              </div>

              <div className="col-12 col-sm-6">
                <SchemaInput schema={schema} name="emailAddress" placeholder={t('email')} label />
              </div>

            </div>

            <div className="row">
              <h4 className="col-12 mt-2">{t('Contact person')}</h4>
            </div>
            <div className="row">

              <div className="col-12 col-sm-2">
                <SchemaInput schema={schema} name="gender" placeholder={t('gendersalutationlabel')} label />
              </div>

              <div className="col-12 col-sm-3">
                <SchemaInput schema={schema} name="firstName" placeholder={t('personfirstname')} label />
              </div>

              <div className="col-12 col-sm-3">
                <SchemaInput schema={schema} name="middleName" placeholder={t('personmiddlename')} label />
              </div>

              <div className="col-12 col-sm-4">
                <SchemaInput schema={schema} name="lastName" placeholder={t('personlastname')} label />
              </div>
            </div>

            <div className="row">
              <h4 className="col-12 mt-2">{t('invoiceaddress')}</h4>
            </div>
            <div className="row">

              <div className="col-12 col-sm-6 col-lg-3">
                <SchemaInput schema={schema} name="invoiceAddressStreet" placeholder={t('street')} label />
              </div>

              <div className="col-12 col-sm-6 col-lg-2">
                <SchemaInput
                  schema={schema}
                  name="invoiceAddressStreetNumber"
                  placeholder={t('streetnumber')}
                  label
                />
              </div>

              <div className="col-12 col-sm-4 col-lg-2">
                <SchemaInput schema={schema} name="invoiceAddressZipcode" placeholder={t('zipcode')} label />
              </div>

              <div className="col-12 col-sm-4 col-lg-3">
                <SchemaInput schema={schema} name="invoiceAddressCity" placeholder={t('city')} label />
              </div>

              <div className="col-12 col-sm-4 col-lg-2">
                <SchemaInput
                  schema={schema}
                  name="invoiceAddressCountryCode"
                  placeholder={t('country')}
                  label
                />
              </div>
            </div>

            <div className="row">
              <h4 className="col-12 mt-2">{t('deliveryaddress')}</h4>
            </div>

            <div className="row">
              <div className="col-12 d-flex flex-row">
                <Input
                  type="checkbox"
                  name="deliveryAddressSameAsinvoiceAddress"
                  id="deliveryAddressSameAsinvoiceAddress"
                  className=""
                />
                <label className="" htmlFor="deliveryAddressSameAsinvoiceAddress">
                  &nbsp;
                  {t('sameasinvoiceaddress')}
                </label>
              </div>
            </div>

            {!this.props.deliveryAddressSameAsinvoiceAddress && (
              <div className="row">
                <SchemaInput
                  schema={schema}
                  name="deliveryAddressStreet"
                  placeholder={t('street')}
                  className="col-12 col-sm-6 col-lg-3"
                  label
                />
                <SchemaInput
                  schema={schema}
                  name="deliveryAddressStreetNumber"
                  placeholder={t('streetnumber')}
                  className="col-12 col-sm-6 col-lg-2 mb-3"
                  label
                />
                <SchemaInput
                  schema={schema}
                  name="deliveryAddressZipcode"
                  placeholder={t('zipcode')}
                  className="col-12 col-sm-4 col-lg-2"
                  label
                />
                <SchemaInput
                  schema={schema}
                  name="deliveryAddressCity"
                  placeholder={t('city')}
                  className="col-12 col-sm-4 col-lg-3"
                  label
                />
                <SchemaInput
                  schema={schema}
                  name="deliveryAddressCountryCode"
                  placeholder={t('country')}
                  className="col-12 col-sm-4 col-lg-2"
                  label
                />
              </div>
            )}

            <OfflineChangesWarning />

            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-default mr-2"
                  onClick={this.props.reset}
                  disabled={this.props.pristine}
                >
                  <Icon icon="undo" className="mr-2" />
                  {t('reset')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={this.props.handleSubmit(this.onSubmit)}
                  disabled={this.props.pristine || this.props.submitting}
                >
                  { this.props.submitting ? (
                    <Icon icon="circle-notch" spin fixedWidth className="mr-2" />
                  ) : (
                    <Icon icon="save" fixedWidth className="mr-2" />
                  ) }
                  {t('savechanges')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.props.handleSubmit(this.onSubmitThenCatalog)}
                  disabled={this.props.pristine || this.props.submitting}
                >
                  { this.props.submitting ? (
                    <Icon icon="circle-notch" spin fixedWidth className="mr-2" />
                  ) : (
                    <Icon icon="save" fixedWidth className="mr-2" />
                  ) }
                  {t('savechangesandgotocatalog')}
                </button>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

const formValues = formValueSelector('customer');

const isValidZipCodeNL = zipcode => zipcode && /^[1-9][0-9]{3}\s*[a-z]{2}$/i.test(zipcode.trim());

/**
 * @param data
 * @param props
 *
 * @returns {{}}
 */
const countryCodesRequireTaxNumber = ['BE', 'CH'];

let ajv = null;
const validateForm = (data, props) => {
  if (!ajv) {
    if (__DEV__) {

      console.log('[ajv] compiling api schema');
    }
    ajv = new Ajv({ removeAdditional: true, allErrors: true, verbose: true });
    ajv.compile(props.schema.set('$id', 'Customer').toJS());
    // this.ajv = ajv;
  }
  if (__DEV__) {

    console.log('data', data);
  }
  const isValid = ajv.validate('Customer', data);
  if (__DEV__) {

    console.log('isValid', isValid);
  }

  const errors = {};

  if (!isValid) {
    if (__DEV__) {

      console.log(ajv.errors);
    }
    ajv.errors.map(ajvErrorMapperCreator(errors, props.t));
  }

  // todo add to ajv validator
  if (data.invoiceAddressCountryCode === 'NL' && !isValidZipCodeNL(data.invoiceAddressZipcode)) {
    errors.invoiceAddressZipcode = props.t('invalidnlzipcodeformat');
  }
  if (!data.deliveryAddressSameAsinvoiceAddress
    && data.deliveryAddressCountryCode === 'NL' && !isValidZipCodeNL(data.deliveryAddressZipcode)) {
    errors.deliveryAddressZipcode = props.t('invalidnlzipcodeformat');
  }

  if (!data.taxNumber && countryCodesRequireTaxNumber.indexOf(data.invoiceAddressCountryCode) !== -1) {
    errors.taxNumber = `${props.t('This field is required')} ${props.t('for values', {
      values: props.t(`country${data.invoiceAddressCountryCode.toLowerCase()}`)
    })}`;
  }

  return errors;
};

const selectSchema = createSelector([
  state => state.app.getIn(['schemas', 'data', 'Customer']),
  (state, props) => props.t,
], (schema, t) => schema
  .setIn(
    ['properties', 'invoiceAddressCountryCode', 'enum_titles'],
    schema.getIn(['properties', 'invoiceAddressCountryCode', 'enum']).map(v => t(`country${v.toLowerCase()}`))
  )
  .setIn(
    ['properties', 'deliveryAddressCountryCode', 'enum_titles'],
    schema.getIn(['properties', 'deliveryAddressCountryCode', 'enum']).map(v => t(`country${v.toLowerCase()}`))
  )
  .setIn(
    ['properties', 'gender', 'enum_titles'],
    schema.getIn(['properties', 'gender', 'enum']).map(v => t(`gendersalutation${v.toLowerCase()}`))
  ));

export default translate()(withRouter(connect((state, props) => {
  let customer = state.customer.get('data')
    .find(c => c.get('id') === parseInt(props.match.params.customerId, 10));
  if (!customer) {
    customer = new Immutable.Map({
      deliveryAddressSameAsinvoiceAddress: true
    });
  }
  return {
    isOffline: !state.app.get('online'),
    isNew: !customer.get('id'),
    schema: selectSchema(state, props),
    profile: state.auth.get('profile'),
    initialValues: customer ? customer.toJS() : {},
    deliveryAddressSameAsinvoiceAddress: formValues(state, 'deliveryAddressSameAsinvoiceAddress'),
    customer,
  };
}, {
  routePush,
  updateCustomer
})(reduxForm({
  form: 'customer',
  onSubmitFail: reduxFormOnSubmitFailScrollToFirstError,
  enableReinitialize: true,
  validate: validateForm
})(Edit))));
