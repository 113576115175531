import Immutable from 'immutable';
import Promise from 'bluebird';
import Notifications from 'react-notification-system-redux';
import uuid from 'uuid';

import api from '../../inc/api';
// import { createWorker } from '../../inc/webWorker';

// const __DEV__ = process.env.NODE_ENV === 'development';

import i18next from '../../inc/i18n';
import moment from '../../inc/moment';
import { hydrateProfile } from './auth';
import { hydrate as hydrateCustomers } from './customer';
import { hydrate as hydrateProducts } from './product';
import { getStorageItem, setStorageItem } from '../../inc/storage';
import { addUrlsToCache } from '../../registerServiceWorker';
import { getProductImageUrl } from '../../inc/util';

// ------------------------------------
// Actions
// ------------------------------------

export const INIT_APP = '@app/INIT';
export const SYNC = '@app/data/SYNC';
export const ONLINE_STATUS = '@app/ONLINE_STATUS';
export const HYDRATE_ENV = '@app/HYDRATE_ENV';
export const HYDRATE_SCHEMAS = '@app/HYDRATE_SCHEMAS';
export const HYDRATE_LOCALE = '@app/locale/HYDRATE';
export const HYDRATE_SETTINGS = '@app/HYDRATE_SETTINGS';
export const WINDOW_RESIZE = '@app/WINDOW_RESIZE';
export const SET_TITLE = '@app/SET_TITLE';
export const TOGGLE_MODAL = '@app/TOGGLE_MODAL';
export const TOGGLE_SIDEBAR = '@app/TOGGLE_SIDEBAR';
export const SET_ACTIVE_LANGUAGE = '@app/SET_ACTIVE_LANGUAGE';

// ------------------------------------
// Action generators
// ------------------------------------

export const setActiveLanguage = languageCode => (dispatch, getState) => {
  if (!languageCode) {
    languageCode = getState().app.getIn(['env', 'data', 'siteLanguage']);
  }
  return dispatch({
    type: SET_ACTIVE_LANGUAGE,
    payload: Promise.resolve(languageCode).then(() => {
      i18next.changeLanguage(languageCode);
      moment.locale(languageCode);
      return languageCode;
    })
  });
};

export const updateSyncStatus = (id, title, status) => ({
  type: '@app/SYNC_STATUS',
  payload: {
    id,
    title,
    status
  }
});

export const hydrateEnv = () => (dispatch) => {
  const syncId = uuid();
  dispatch(updateSyncStatus(syncId, i18next.t('Retrieving configuration'), false));
  return dispatch({
    type: HYDRATE_ENV,
    payload: api.get('/env').then((env) => {
      if (!env) {
        return env;
      }
      Object.keys(env).forEach((key) => {
        process.env[key] = env[key];
      });
      dispatch(updateSyncStatus(syncId, i18next.t('Retrieved configuration'), true));
      return env;
    })
  });
};

export const hydrateLocale = () => (dispatch) => {
  const syncId = uuid();
  dispatch(updateSyncStatus(syncId, i18next.t('Retrieving translations...'), false));
  return dispatch({
    type: HYDRATE_LOCALE,
    payload: api.get('/locale').then((data) => {
      Object.keys(data).forEach((languageCode) => {
        i18next.addResourceBundle(languageCode.toLowerCase(), 'common', data[languageCode]);
      });
      dispatch(updateSyncStatus(syncId, i18next.t('Retrieved translations.'), true));
      return data;
    })
  });
};

export const hydrateSchemas = () => (dispatch) => {
  const syncId = uuid();
  dispatch(updateSyncStatus(syncId, i18next.t('Retrieving schemas...'), false));
  return dispatch({
    type: HYDRATE_SCHEMAS,
    payload: api.get('/schema').then((data) => {
      dispatch(updateSyncStatus(syncId, i18next.t('Retrieved schemas.'), true));
      return data;
    })
  });
};

export const onWindowResize = () => ({
  type: WINDOW_RESIZE,
  payload: {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  }
});

export const setTitle = title => ({ type: SET_TITLE, payload: title });
export const toggleModal = name => ({ type: TOGGLE_MODAL, payload: name });

export const startDataSync = () => (dispatch, getState) => {

  console.log('Initializing Sync...');

  const state = getState();

  if (state.app.getIn(['env', 'data', 'siteLanguage']) !== state.app.getIn(['env', 'data', 'activeLanguage'])) {
    const siteLanguage = state.app.getIn(['env', 'data', 'siteLanguage']);
    const activeLanguage = state.app.getIn(['env', 'data', 'activeLanguage']);
    dispatch(setActiveLanguage(activeLanguage)).then((languageCode) => {
      dispatch(Notifications.info({
        message: i18next.t('changedlanguagebyprofile', {
          previous: i18next.t(`language${siteLanguage}`),
          new: i18next.t(`language${activeLanguage}`)
        }),
      }));
      return languageCode;
    });
  }

  if (!state.app.get('online')) {

    console.log('Skipping Sync... OFFLINE');
    return Promise.resolve();
  }

  const promises = [];
  promises.push(dispatch(hydrateEnv()).then(() => dispatch(setTitle())));
  promises.push(dispatch(hydrateLocale()));
  promises.push(dispatch(hydrateProfile()).then((profile) => {
    const activeLanguage = state.app.getIn(['env', 'data', 'activeLanguage']);
    const profileLanguage = profile.languageShort.toLowerCase();
    return dispatch(setActiveLanguage(profileLanguage)).then((languageCode) => {
      if (profileLanguage !== activeLanguage) {
        dispatch(Notifications.info({
          message: i18next.t('changedlanguagebyprofile', {
            previous: i18next.t(`language${activeLanguage}`),
            new: i18next.t(`language${profileLanguage}`)
          }),
        }));
      }
      return languageCode;
    });
  }));
  promises.push(dispatch(hydrateSchemas()));
  promises.push(dispatch(hydrateCustomers()));
  promises.push(dispatch(hydrateProducts()));

  const id = uuid();
  dispatch(updateSyncStatus(id, i18next.t('Precaching product images...'), false));

  return dispatch({
    type: SYNC,
    payload: Promise.all(promises).then(() => {
      const s = getState();

      const productTypes = s.product.get('data');
      const images = s.product.get('data').reduce(
        (urls, productType) => urls.concat(productType.get('products').map(p => getProductImageUrl(p, productType))),
        Immutable.List()
      ).concat(s.customer.get('data').reduce(
        (urls, customer) => urls.concat(customer.get('history').map((p) => {
          const productType = productTypes.filter(pt => pt.get('id') === p.get('retailProductTypeId')).first();
          return getProductImageUrl(p, productType);
        })),
        Immutable.List()
      )).filter(v => !!v && v.indexOf('?id=0') === -1)
        .toSet();


      console.log(`Precaching ${images.size} images...`);
      // let c = 0;
      return addUrlsToCache(images, () => {
        // const perc = Math.floor((100 / images.size) * ++c);
        // dispatch(updateSyncStatus(id, `Product afbeeldingen precachen (${perc}%) ...`, false));
      }).then((res) => {

        console.log(`Precached ${res.length} images...`);
        dispatch(updateSyncStatus(id, i18next.t('Precached product images.'), true));
      });
    }).then(() => {
      dispatch(Notifications.success({
        message: i18next.t('Sync complete'),
      }));
    })
  });
};

export const initApp = () => (dispatch, getState) => {
  const state = getState();

  console.log('Initializing App...');

  window.addEventListener('load', () => {

    console.log('window.load...');
    function updateOnlineStatus() {
      dispatch({
        type: ONLINE_STATUS,
        payload: navigator.onLine
      });
    }
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  });

  const i18nData = state.app.getIn(['i18n', 'data'], {}).toJS();
  Object.keys(i18nData).forEach((languageCode) => {
    i18next.addResourceBundle(languageCode.toLowerCase(), 'common', i18nData[languageCode]);
  });

  const promises = [
    dispatch(setTitle()),
    // dispatch(setActiveLanguage(i18next.language))
  ];

  return dispatch({
    type: INIT_APP,
    payload: Promise.all(promises).then((data) => {

      console.log('%cApplication initialized!', 'background: green; color: #fff; padding: 4px');
      return data;
    })
  });
};

export const toggleSidebar = expanded => ({
  type: TOGGLE_SIDEBAR,
  payload: !expanded
});

// ------------------------------------
// Default State
// ------------------------------------

const envState = getStorageItem('env', {});
if (envState && !envState.siteLanguage) {
  envState.siteLanguage = i18next.language;
  envState.activeLanguage = i18next.language;
}

const State = new Immutable.Record({
  isInitializing: true,
  hasInitialized: false,
  isSyncing: false,
  hasSynced: getStorageItem('hasSynced', false),
  syncError: null,
  syncStatus: Immutable.Map(),
  online: navigator.onLine,
  env: new Immutable.Map({
    isFetching: false,
    error: null,
    data: Immutable.fromJS(envState),
  }),
  i18n: new Immutable.Map({
    isFetching: false,
    error: null,
    data: Immutable.fromJS(getStorageItem('i18n', {})),
  }),
  schemas: new Immutable.Map({
    isFetching: false,
    error: null,
    data: Immutable.fromJS(getStorageItem('schemas', {})),
  }),
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  titleSuffix: document.title,
  title: '',
  filter: new Immutable.Map(),
  modal: new Immutable.Map(),
  location: new Immutable.Map(),
  MODAL_OVERLAY: ''
});

export const MODAL_OVERLAY = '@app/MODAL_OVERLAY';

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  '@@router/LOCATION_CHANGE': (state, { payload }) => state
    .set('location', payload),
  '@app/SYNC_STATUS': (state, { payload }) => state
    .setIn(['syncStatus', payload.id], Immutable.fromJS(payload)),
  [ONLINE_STATUS]: (state, { payload }) => state
    .set('online', payload),
  [SET_ACTIVE_LANGUAGE]: (state, { status, payload }) => {
    switch (status) {
      case 'success':
        return state
          .setIn(['env', 'data', 'activeLanguage'], payload);
    }
    return state;
  },
  [SYNC]: (state, { status, payload }) => {

    switch (status) {
      case 'pending':
        return state.set('isSyncing', true);
      case 'success':
        return state.set('isSyncing', false).set('hasSynced', true);
      case 'error':
        return state.set('isSyncing', false).set('syncError', payload);
      default:
        return state;
    }
  },
  [INIT_APP]: (state, { status, payload }) => {

    switch (status) {
      case 'pending':
        return state.set('isInitializing', true);
      case 'success':
        return state.set('isInitializing', false).set('hasInitialized', true);
      case 'error':
        return state.set('isInitializing', false).set('initError', payload);
      default:
        return state;
    }
  },
  [TOGGLE_SIDEBAR]: (state, { payload }) => state
    .setIn(['settings', 'data', 'sidebarExpanded'], payload),
  [MODAL_OVERLAY]: (state, { payload }) => state
    .set('MODAL_OVERLAY', payload),
  [HYDRATE_LOCALE]: (state, { status, payload }) => {
    switch (status) {
      case 'success':
        return state
          .setIn(['i18n', 'data'], Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [HYDRATE_ENV]: (state, { status, payload }) => {
    switch (status) {
      case 'success':
        return state
          .mergeDeepIn(['env', 'data'], Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [HYDRATE_SCHEMAS]: (state, { status, payload }) => {
    switch (status) {
      case 'success':
        return state
          .setIn(['schemas', 'data'], Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [WINDOW_RESIZE]: (state, { payload }) => state
    .set('windowWidth', payload.windowWidth)
    .set('windowHeight', payload.windowHeight),
  [SET_TITLE]: (state, { payload }) => {
    document.title = payload ? `${payload} - ${state.get('titleSuffix')}` : state.get('titleSuffix');

    const env = state.getIn(['env', 'NODE_ENV'], process.env.NODE_ENV);
    if (env && env !== 'production' && document.title.toLowerCase().indexOf(env) === -1) {
      document.title = `[${env.toUpperCase()}] ${document.title}`;
    }

    if (!payload) {
      return state.set('title', '');
    }
    return state.set('title', payload);
  },
  [TOGGLE_MODAL]: (state, { payload }) =>
    state.setIn(['modal', payload], !state.getIn(['modal', payload], false))
};

const persistState = (state) => {
  setStorageItem('env', state.getIn(['env', 'data']).toJS());
  setStorageItem('i18n', state.getIn(['i18n', 'data']).toJS());
  setStorageItem('schemas', state.getIn(['schemas', 'data']).toJS());
  setStorageItem('location', state.location);
  setStorageItem('hasSynced', state.get('hasSynced'));
  return state;
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? persistState(handler(state, action)) : state;
}
