import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import withRouter from 'react-router/withRouter';
import { replace as routeReplace } from 'react-router-redux';
import { createSelector } from 'reselect';
import { translate } from 'react-i18next';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import Grid from 'react-virtualized/dist/commonjs/Grid';
import debounce from 'lodash/debounce';

import CatalogCategories from '../catalog/Categories';
import ProductBox from './ProductBox';
import Icon from '../../../component/Icon';
import { setFilter } from '../../../store/modules/product';
import Toggle from '../../../component/Toggle';
import { price } from '../../../inc/util';
import withCustomer from '../../../component/withCustomer';

class Products extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    filter: PropTypes.instanceOf(Immutable.Map).isRequired,
    routeReplace: PropTypes.func.isRequired,
    productTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    columnCount: PropTypes.number,
    columnWidth: PropTypes.number,
    rowHeight: PropTypes.number,
    initialProductTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    productType: PropTypes.instanceOf(Immutable.Map),
    products: PropTypes.instanceOf(Immutable.List),
  };

  static defaultProps = {
    columnCount: 4,
    columnWidth: 270,
    rowHeight: 340,
    initialProductTypeId: null,
    productTypeId: null,
    productType: null,
    products: null,
  };

  static contextTypes = {
    customer: PropTypes.instanceOf(Immutable.Map),
  };

  constructor(props) {
    super(props);
    this.scrollContainer = React.createRef();
  }

  state = {
    value: this.props.filter.get('query', '')
  };

  componentWillMount() {
    if (!this.props.productTypeId) {
      this.props.routeReplace(`/customer/${this.context.customer.get('id')}/catalog/${this.props.initialProductTypeId}`);
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.productTypeId && this.scrollContainer && this.props.productTypeId !== props.productTypeId) {
      this.scrollContainer.current.scrollTop = 0;
    }
  }

  onFilterChange = (e) => {
    e.persist();
    this.setState({ value: e.target.value });
    this.onDebouncedFilterChange(e);
  };

  onDebouncedFilterChange = debounce((e) => {
    this.props.setFilter(this.props.filter.set('query', e.target.value));
  }, 50);

  onFilterClear = () => {
    this.setState({ value: '' });
    this.props.setFilter(this.props.filter.set('query', ''));
  };

  onToggleOwnDesigns = (active) => {
    this.props.setFilter(this.props.filter.set('ownDesigns', active));
  };

  getRowHeight = (productType) => {
    if (productType.get('id') === 'display') {
      return 450;
    }
    if (productType.get('id') === 178509 || productType.get('id') === 348387) {
      return 270;
    }
    if (productType.get('id') === 178510) {
      return 270;
    }
    return this.props.rowHeight;
  };

  renderProduct = ({
    columnIndex, key, rowIndex, style
  }) => {
    // col-6 col-md-4 col-lg-3 col-xl-2 omega-2 omega-md-4
    const product = this.props.products.get((rowIndex * this.props.columnCount) + columnIndex);
    if (!product) {
      return null;
    }
    return (
      <ProductBox
        style={style}
        key={key + product.get('id')}
        product={product}
        productType={this.props.productType}
      />
    );
  };

  renderMetaInformation() {
    const { t, filter, productType } = this.props;
    const isDisplayingOwnDesigns = filter.get('ownDesigns', false);
    if (isDisplayingOwnDesigns) {
      return (
        <div className="d-flex flex-1 flex-row align-items-start justify-content-between mb-3">
          <div className="">
            Meest recente eigen ontwerpen
            Minimale bestelhoeveelheid (per stuk): 6 exemplaren
          </div>
          <div className="">
            { t('unitprice') } &euro; { productType.get('unitprice', 4) }
          </div>
        </div>
      );
    }

    if (productType.get('id') === 'display') {
      return (
        <div>
          <div className="d-flex flex-1 flex-row align-items-start justify-content-between mb-3">
            <div>
              { t('shippingCostsPerRetailProductType', {
                retailProductType: productType.get('id'),
                shippingCosts: price(productType.getIn(['shippingCosts', 'NL'])),
              }) }
            </div>
          </div>
        </div>
      );
    }

    const extraInformation = productType.get('extraInformation');
    return (
      <div>
        <div className="d-flex flex-1 flex-row align-items-start justify-content-between mb-3">
          <div className="">
            { t('minimalorderamount') } { productType.get('minimalOrderAmount') } { t('pieces') }.
            &nbsp;
            { t('orderquantity') } { productType.get('orderQuantity') } { t('piece') }.
          </div>
          <div className="">
            { t('unitprice') } &euro; { productType.get('unitprice', 4) }
          </div>
        </div>
        { extraInformation && (
          <div className="d-flex flex-row p-2 flex-no-shrink align-items-center justify-content-center alert alert-info">
            <Icon icon="info-circle" />&nbsp;
            { extraInformation }
          </div>
        ) }
      </div>
    );
  }

  renderNoRows = () => (
    <div className="d-flex flex-1 justify-content-center">
      <Icon icon="exclamation-circle" className="mr-2" />
      { this.props.t('noresults') }
    </div>
  );

  render() {
    if (!this.props.productType || !this.props.products) {
      return null;
    }
    const filterString = this.state.value;
    const {
      productType, t, products, filter
    } = this.props;
    const isDisplayingOwnDesigns = filter.get('ownDesigns', false);

    const rowHeight = this.getRowHeight(this.props.productType);
    const rowCount = Math.ceil(products.size / this.props.columnCount);
    const containerHeight = products.size === 0 ? 100 : rowCount * (rowHeight + 2);

    return (
      <div className="d-flex flex-column flex-1 flex-no-shrink">
        <div className="d-flex flex-column flex-no-shrink justify-content-between box-shadow-bottom p-2 " style={{ zIndex: 99 }}>
          <div className="d-flex flex-column flex-md-row flex-no-shrink align-items-center justify-content-between">
            <div>
              <h2 className="mr-3 mb-0">
                { t(productType.get('code')) }
              </h2>
            </div>
            <div className="d-flex flex-row align-items-center">
              { this.props.productType.get('hasCustomDesign') && (
                <Toggle
                  className="mr-3"
                  name="displayOwnDesigns"
                  label={t('customdesign')}
                  onToggle={this.onToggleOwnDesigns}
                  toggled={isDisplayingOwnDesigns}
                />
              ) }
              <div className="visible-md">
                <div className="SearchInput d-flex flex-row flex-1 align-items-center mr-3">
                  <Icon icon="search" className={`SearchInput__Icon mr-3 ${filterString ? ' SearchInput__Icon--active' : ''} mr-3`} />
                  <input
                    type="text"
                    placeholder={`${t('search')}...`}
                    onChange={this.onFilterChange}
                    value={filterString}
                    className="SearchInput__Input form-control"
                    style={{ width: 200 }}
                  />
                  { filterString && (
                    <Icon icon="times" className="SearchInput__Clear" onClick={this.onFilterClear} />
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="d-flex flex-row">
          <CatalogCategories {...this.props} />
          <div className="d-flex flex-1 flex-overflow-scroll p-2 pt-4" ref={this.scrollContainer}>
            <div className="container container-xl">
              { this.renderMetaInformation() }

              <div className="d-flex flex-1 flex-column">
                <div className="d-flex flex-1 flex-column overflow-hidden" style={{ flex: '1 1 auto', height: containerHeight }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <Grid
                        height={height}
                        width={width}
                        columnCount={this.props.columnCount}
                        columnWidth={width / this.props.columnCount}
                        rowHeight={rowHeight}
                        overscanRows={2}
                        rowCount={rowCount}
                        cellRenderer={this.renderProduct}
                        noContentRenderer={this.renderNoRows}
                      />
                    )}
                  </AutoSizer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selectProductType = createSelector([
  productState => productState.get('data'),
  (productTypeState, productTypeId) => productTypeId,
], (productTypeState, productTypeId) => {
  const productType = productTypeState.find(item => item.get('id') === productTypeId);

  if (!productType) {
    return null;
  }

  return productType;
});

const selectCustomer = createSelector([
  state => state.customer.get('data'),
  (state, customerId) => customerId,
], (customers, customerId) => customers.find(c => c.get('id') === customerId));

const selectLanguage = createSelector([
  state => state.app.getIn(['env', 'language'], 'nl')
], language => language);

const selectThemaId = createSelector([
  state => selectLanguage(state)
], (language) => {
  if (language === 'de') {
    return 58569;
  }
  return 33456;
});

const selectSiteCountryCodeId = createSelector([
  state => selectLanguage(state)
], (language) => {
  if (language === 'de') {
    return 1;
  }
  return 0;
});


const selectCustomerCountryCode = createSelector([
  customer => customer
], (customer) => {
  const customerCountryCode = customer.get('deliveryAddressSameAsinvoiceAddress')
    ? customer.get('invoiceAddressCountryCode')
    : customer.get('deliveryAddressCountryCode');
  switch (customerCountryCode) {
    case 'CH':
      return customerCountryCode;
    case 'DE':
    case 'AT':
      return 'DE';
    default:
      return 'NL';
  }
});

const selectProducts = createSelector([
  state => selectLanguage(state),
  (state, props) => selectCustomer(state, parseInt(props.match.params.customerId, 10)),
  state => selectThemaId(state),
  state => selectSiteCountryCodeId(state),
  (state, props, productTypeId) => selectProductType(state.product, productTypeId),
  (state, props, productTypeId, filter) => filter,
], (language, customer, themaId, siteCountryCodeId, productType, filter) => {
  if (!productType) {
    return null;
  }

  const re = new RegExp(`.*${filter.get('query', '')}.*`, 'i');

  if (customer && productType.get('hasCustomDesign') && filter.get('ownDesigns')) {
    return customer.get('history')
      .filter(p => productType.get('id') === p.get('retailProductTypeId') && re.test(p.get('title')));
  }

  const customerCountryCode = selectCustomerCountryCode(customer);
  if (productType.get('id') === 'display') {
    return productType.getIn(['products', customerCountryCode === 'AT' ? 'DE' : customerCountryCode])
      .filter(p => re.test(p.get('id')) || re.test(p.get('text')));
  }
  themaId = customerCountryCode === 'DE' ? 58569 : 33456;
  siteCountryCodeId = customerCountryCode === 'DE' ? 1 : 0;
  return productType.get('products').filter((p) => {
    if (customerCountryCode === 'CH') {
      return false;
    }
    if (productType.get('id') === 178512) {
      return p.get('themaId') === themaId;
    }
    return p.get('siteCountryCode') === siteCountryCodeId;
  }).filter(p => re.test(p.get('id')) || re.test(p.get('text')));
});

const selectColumnCount = createSelector([
  state => state.app.get('windowWidth')
], (width) => {
  if (width >= 1280) {
    return 4;
  }
  if (width >= 1000) {
    return 3;
  }
  if (width >= 640) {
    return 2;
  }
  return 1;
});

export default translate()(withCustomer(withRouter(connect((state, props) => {
  let productTypeId = parseInt(props.match.params.productTypeId, 10);
  if (props.match.params.productTypeId === 'display') {
    productTypeId = 'display';
  }
  const filter = state.product.get('filter');
  return {
    columnCount: selectColumnCount(state),
    productTypeId,
    initialProductTypeId: state.product.getIn(['data', 0, 'id']),
    productType: selectProductType(state.product, productTypeId),
    products: selectProducts(state, props, productTypeId, filter),
    filter
  };
}, {
  routeReplace,
  setFilter
})(Products))));
