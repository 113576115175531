import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';

import CustomerListContainer from './Overview';
import SelectedCustomerContainer from './SelectedCustomerContainer';

// import CustomerCatalogContainer from './Catalog';
// import CustomerCatalogContainer from './Catalog';
// <Route path="/customer/:customerId/edit" exact component={CustomerEditContainer} />
// <Route path="/customer/:customerId/catalog" exact component={CustomerCatalogContainer} />
// <Route path="/customer/:customerId/catalog/:productTypeId" component={CustomerCatalogContainer} />

class IndexContainer extends Component {
  static propTypes = {
  };

  static defaultProps = {
  };

  render() {
    return (
      <Switch>
        <Route path="/customer" exact component={CustomerListContainer} />
        <Route path="/customer/:customerId"component={SelectedCustomerContainer} />
      </Switch>
    );
  }
}

export default IndexContainer;
