import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ErrorBoundary.css';
import Icon from '../Icon';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node
  };
  static defaultProps = {
    children: null
  };

  state = { error: null };

  componentDidMount() {
    // BUILDUP
    // The Web Speech API may mount without its voices loaded, making any
    // on-mount speech fail.
    if (window.speechSynthesis) {
      window.speechSynthesis.onvoiceschanged = () => {
        this.forceUpdate();
      };
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   // We also need to decide when to invoke the speech method. This was
  //   // hooked into our component lifecycle automatically, but no longer is.
  //   if (prevState.message !== this.state.message) {
  //     this.speak(this.state.message);
  //   }
  // }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (process.env.NODE_ENV === 'production' && window.Raven) {
      window.Raven.captureException(error, { extra: errorInfo });
    }
  }

  componentWillUnmount() {
    // TEARDOWN
    // We need to do unmount cleanup, to ensure that our queue is emptied
    // when the component is removed.
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel();
    }
  }

  onClickShowErrorDialog = () => {
    if (process.env.NODE_ENV === 'production' && window.Raven && window.Raven.lastEventId()) {
      window.Raven.showReportDialog();
    }
  };

  // The SpeechSynthesis API is invoked with an 'utterance', an object that
  // holds all necessary information about the voice, language, and message.
  utterance = window.SpeechSynthesisUtterance ? new window.SpeechSynthesisUtterance() : null;

  speak() {
    if (process.env.NODE_ENV === 'production') {
      return;
    }
    // This method handles the actual 'speaking', using the SpeechSynthesis
    // API. For simplicity, we grab the first voice that supports the language
    // specified in the props.
    // const { language, message } = this.props;

    if (window.speechSynthesis && this.utterance) {
      window.speechSynthesis.cancel();
      const language = 'de';
      this.utterance.voice = window.speechSynthesis.getVoices().find(voice => voice.lang.startsWith(language));
      this.utterance.lang = language;
      this.utterance.text = 'NEIN, NEIN, NEIN';
      this.utterance.pitch = 1.4;
      this.utterance.rate = 1.4;
      window.speechSynthesis.speak(this.utterance);
    }

    // language = 'ja';
    // this.utterance.voice = window.speechSynthesis.getVoices().find(voice => voice.lang.startsWith(language));
    // this.utterance.lang = language;
    // this.utterance.pitch = 0.2;
    // this.utterance.rate = 1.6;
    // this.utterance.text = 'Error occurred';
    // window.speechSynthesis.speak(this.utterance);
  }

  render() {
    if (this.state.error) {
      this.speak();
      // render fallback UI
      return (
        <div
          className="d-flex flex-column align-items-center ErrorBoundary modal-container"
        >
          <Icon icon="exclamation-triangle" />
          <p>
            <strong>
              Er is iets vreselijks gebeurt.
              Dit hoort ook niet te gebeuren!
            </strong>
          </p>
          {
            window.Raven && window.Raven.lastEventId() && (
              <div
                role="button"
                tabIndex="-1"
                onKeyDown={this.onKeyDown}
                onClick={this.onClickShowErrorDialog}
              >
                Wij zijn ervan op de hoogte, je kan ook hier klikken en een rapport insturen.
              </div>
            )
          }
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
