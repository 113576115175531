import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import withRouter from 'react-router/withRouter';
// import Link from 'react-router-dom/Link';

import './Categories.css';
import ProductType from './ProductType';

class Categories extends Component {
  static propTypes = {
    activeProductTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    productTypes: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  static defaultProps = {
    activeProductTypeId: null
  };

  static contextTypes = {
    customer: PropTypes.instanceOf(Immutable.Map)
  };

  render() {
    return (
      <div className="CategoryList d-flex flex-column flex-overflow-scroll" style={{ zIndex: 98 }}>
        { this.props.productTypes.map(productType => (
          <ProductType
            key={productType.get('id')}
            customer={this.context.customer}
            productType={productType}
            active={this.props.activeProductTypeId === productType.get('id')}
          />
        ))}
      </div>
    );
  }
}

const selectCustomer = createSelector([
  state => state.customer.get('data'),
  (state, customerId) => customerId,
], (customers, customerId) => customers.find(c => c.get('id') === customerId));

const selectCustomerCountryCode = createSelector([
  customer => customer
], (customer) => {
  const customerCountryCode = customer.get('deliveryAddressSameAsinvoiceAddress')
    ? customer.get('invoiceAddressCountryCode')
    : customer.get('deliveryAddressCountryCode');
  switch (customerCountryCode) {
    case 'CH':
      return customerCountryCode;
    case 'DE':
    case 'AT':
      return 'DE';
    default:
      return 'NL';
  }
});

const selectProductTypes = createSelector([
  state => state.product.get('data'),
  (state, props) => selectCustomerCountryCode(selectCustomer(state, parseInt(props.match.params.customerId, 10))),
], (productTypes, customerCountryCode) => productTypes
  .filter(productType => productType.get('id') === 'display' || customerCountryCode !== 'CH'));

export default withRouter(connect((state, props) => ({
  productTypes: selectProductTypes(state, props),
  activeProductTypeId: props.match.params.productTypeId === 'display'
    ? props.match.params.productTypeId : parseInt(props.match.params.productTypeId, 10),
}))(Categories));
