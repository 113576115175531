import Immutable from 'immutable';
// import moment from 'moment';

import api from '../../inc/api';
import { getStorageItem, setStorageItem } from '../../inc/storage';

// ------------------------------------
// Actions
// ------------------------------------

export const LOGIN = '@app/auth/LOGIN';
export const LOGOUT = '@app/auth/LOGOUT';
export const HYDRATE_PROFILE = '@app/auth/HYDRATE_PROFILE';
export const UPDATE_PROFILE = '@app/auth/UPDATE_PROFILE';
export const SET_PENDING = '@app/auth/SET_PENDING';
export const REFRESH_TOKEN = '@app/auth/REFRESH_TOKEN';
export const UPDATE_TOKEN = '@app/auth/UPDATE_TOKEN';
export const CLEAR_TOKENS = '@app/auth/CLEAR_TOKENS';
export const RESET = '@app/auth/RESET';
export const RESET_RESET_RESULT = '@app/auth/RESET_RESET_RESULT';

// ------------------------------------
// Action generators
// ------------------------------------

export const login = data => ({
  type: LOGIN,
  payload: api.post('/auth/login', data).type('form')
});

export const logout = () => ({
  type: LOGOUT,
  payload: api.post('/auth/logout')
});

export const reset = data => ({
  type: RESET,
  payload: api.post('/auth/reset', data)
});

export const resetResetResult = () => ({
  type: RESET_RESET_RESULT
});

export const hydrateProfile = () => ({
  type: HYDRATE_PROFILE,
  payload: api.get('/auth/profile')
});

export const updateProfile = data => (dispatch, getState) => {
  const state = getState();
  if (!state.app.get('online')) {
    dispatch({
      type: SET_PENDING,
      payload: data
    });
    return Promise.resolve();
  }
  return dispatch({
    type: UPDATE_PROFILE,
    payload: api.put('/auth/profile', data)
  });
};

export const clearTokens = () => {
  localStorage.removeItem('authState');
  sessionStorage.removeItem('authState');
  return {
    type: CLEAR_TOKENS
  };
};

// ------------------------------------
// Default State
// ------------------------------------
// JSON.parse(window.localStorage.getItem('authState')) || {}
// Immutable.fromJS(JSON.parse(window.localStorage.getItem('userContexts')) || {}).find()
const State = new Immutable.Record({
  isLoggedOut: true,
  isLoggingOut: false,
  inProgress: false,
  error: null,
  data: Immutable.fromJS(JSON.parse(window.localStorage.getItem('authState')) || {}),
  profileInProgress: false,
  profileError: null,
  profileUpdateInProgress: false,
  profileUpdateError: null,
  profile: Immutable.fromJS(getStorageItem('profile', {})),
  resetInProgress: false,
  resetError: null,
  resetResult: null,
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [CLEAR_TOKENS]: state => state
    .set('data', Immutable.Map()),
  [RESET_RESET_RESULT]: state => state
    .set('resetResult', false),
  [RESET]: (state, { status, payload }) => {
    switch (status) {
      case 'pending':
        return state
          .set('resetInProgress', true);
      case 'success':
        return state
          .set('resetInProgress', false)
          .set('resetError', null)
          .set('resetResult', payload);
      case 'error':
        return state
          .set('resetInProgress', false)
          .set('resetError', payload);
      default:
        return state;
    }
  },
  [LOGIN]: (state, { status, payload }) => {
    switch (status) {
      case 'pending':
        return state
          .set('inProgress', true);
      case 'success':
        return state
          .set('inProgress', false)
          .set('error', null)
          .set('data', Immutable.fromJS(payload));
      case 'error':
        return state
          .set('inProgress', false)
          .set('error', payload);
      default:
        return state;
    }
  },
  [LOGOUT]: (state, { status, payload }) => {
    switch (status) {
      case 'pending':
        return state
          .set('isLoggingOut', false);
      case 'success':
        return state
          .set('isLoggingOut', false)
          .set('isLoggedOut', true)
          .set('error', null)
          .set('data', Immutable.Map());
      case 'error':
        return state
          .set('isLoggingOut', false)
          .set('error', payload);
      default:
        return state;
    }
  },
  [HYDRATE_PROFILE]: (state, { status, payload }) => {
    switch (status) {
      case 'pending':
        return state
          .set('profileInProgress', true);
      case 'success':
        return state
          .set('profileInProgress', false)
          .set('profileError', null)
          .set('profile', Immutable.fromJS(payload));
      case 'error':
        return state
          .set('profileInProgress', false)
          .set('profileError', payload);
      default:
        return state;
    }
  },
  [SET_PENDING]: (state, { payload }) => state
    .set('profile', Immutable.fromJS(payload).set('pending', true)),
  [UPDATE_PROFILE]: (state, { status, payload }) => {
    switch (status) {
      case 'pending':
        return state
          .set('profileUpdateInProgress', true);
      case 'success':
        return state
          .set('profileUpdateInProgress', false)
          .set('profileUpdateError', null)
          .set('profile', Immutable.fromJS(payload));
      case 'error':
        return state
          .set('profileUpdateInProgress', false)
          .set('profileUpdateError', payload);
      default:
        return state;
    }
  },
  [UPDATE_TOKEN]: (state, { payload }) => state
    .setIn(['tokens', Immutable.fromJS(payload)]),
};

const persistState = (state) => {
  const authState = state.get('data');
  if (authState) {
    setStorageItem('authState', authState.toJS());
  }
  setStorageItem('profile', state.get('profile').toJS());
  return state;
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  // if (handler) {
  //   state = state.set('token', Immutable.fromJS(JSON.parse(window.localStorage.getItem('authState')) || {}));
  // }
  return handler ? persistState(handler(state, action)) : state;
}
