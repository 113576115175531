import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import './Input.css';

import Select from './input/Select';

class Input extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    // ...fieldPropTypes,
  };

  static defaultProps = {
    type: 'text',
    className: '',
    placeholder: '',
  };

  renderTextArea = ({ input, meta, ...rest }) => {
    const hasError = meta.touched && meta.error;
    return (
      <textarea
        {... input}
        className={`form-control${hasError ? ' is-invalid' : ''}`}
        placeholder={rest.placeholder}
        autoComplete={rest.autoComplete}
        required={rest.required ? 'required' : ''}
      />
    );
  };

  renderSelect = ({ input, meta, ...rest }) => {
    const hasError = meta.touched && meta.error;
    return (
      <Select
        input={input}
        meta={meta}
        {...rest}
        id={input.name}
        autoComplete={rest.autoComplete}
        className={`form-control${hasError ? ' is-invalid ' : ''}`}
        placeholder={rest.placeholder}
        required={rest.required ? 'required' : ''}
        options={rest.options}
      />
    );
  };

  renderInputType = ({ input, meta, ...rest }) => {
    if (rest.type === 'textarea') {
      return this.renderTextArea({ input, meta, ...rest });
    }
    if (rest.type === 'select') {
      return this.renderSelect({ input, meta, ...rest });
    }
    // default
    const hasError = meta.touched && meta.error;

    const classNames = [];
    if (rest.type !== 'checkbox') {
      classNames.push('form-control');
    }
    if (hasError) {
      classNames.push('is-invalid');
    }
    return (
      <input
        {... input}
        id={input.name}
        type={rest.type || 'text'}
        autoComplete={rest.autoComplete}
        className={classNames.join(' ')}
        placeholder={rest.placeholder}
        required={rest.required ? 'required' : ''}
      />
    );
  };

  renderLabel = ({ input, meta, ...rest }) => {
    if (!rest.label) {
      return null;
    }
    let label = rest.placeholder;
    if (rest.label === true) {
      label = rest.placeholder;
    }
    return (
      // eslint-disable-next-line jsx-a11y/label-has-for
      <label htmlFor={input.name}>{label}</label>
    );
  };

  renderInput = (props) => {
    const hasError = props.meta.touched && props.meta.error;
    if (props.type === 'hidden') {
      return this.renderInputType(props);
    }
    return (
      <div className={`${props.className} mb-2 ${hasError ? 'has-danger' : ''}`}>
        { this.renderLabel(props) }
        { this.renderInputType(props) }
        { hasError && (
          <div className="invalid-feedback">
            { props.meta.error }
          </div>
        ) }
      </div>
    );
  };

  render() {
    if (this.props.type === 'textarea') {
      return <Field component={this.renderInput} {... this.props} />;
    }
    if (this.props.type === 'select') {
      return <Field component={this.renderInput} {... this.props} />;
    }
    return <Field component={this.renderInput} {... this.props} />;
  }
}

export default Input;
