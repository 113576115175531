import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import hoistStatics from 'hoist-non-react-statics';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withCustomer(WrappedComponent) {
  class WithCustomer extends React.Component {
    static propTypes = {
    };

    static defaultProps = {
    };

    static contextTypes = {
      customer: PropTypes.instanceOf(Immutable.Map)
    };

    render() {
      const { customer } = this.context;
      return React.createElement(
        WrappedComponent,
        {
          customer,
          customerId: customer && customer.get('id'),
          ...this.props
        },
      );
    }
  }

  withCustomer.WrappedComponent = WrappedComponent;

  // WithUserContext.propTypes = {
  // };
  //
  // WithUserContext.contextTypes = {
  // };
  //
  // WithUserContext.childContextTypes = {
  // };

  withCustomer.displayName = `withCustomer(${getDisplayName(WrappedComponent)})`;

  return hoistStatics(WithCustomer, WrappedComponent);
}
