import Promise from 'bluebird';
import Immutable from 'immutable';
import { getStorageItem, setStorageItem } from '../../inc/storage';
import moment from '../../inc/moment';
import api from '../../inc/api';

// ------------------------------------
// Actions
// ------------------------------------

export const ADD_ITEM = '@app/cart/ADD_ITEM';
export const REMOVE_ITEM = '@app/cart/REMOVE_ITEM';
export const REMOVE_CART = '@app/cart/REMOVE_CART';
export const SUBMIT_CART = '@app/cart/SUBMIT_CART';
export const SET_COMMENT = '@app/cart/SET_COMMENT';
export const SET_CART_PENDING = '@app/cart/SET_CART_PENDING';

// ------------------------------------
// Helpers
// ------------------------------------

// const findCustomerCartPendingIndex = (state, customerId) =>
//   state.get('carts').findIndex(i => i.get('customerId') === customerId && i.get('pending', false));

// const findCustomerCartPending = (state, customerId) =>
//   state.getIn(['carts', findCustomerCartPendingIndex(state, customerId)]);

const findCustomerCartIndex = (state, customerId) =>
  state.get('carts').findIndex(i => i.get('customerId') === customerId && !i.get('pending', false));

const findCustomerCart = (state, customerId) => state.getIn(['carts', findCustomerCartIndex(state, customerId)]);

// ------------------------------------
// Action generators
// ------------------------------------
export const removeCart = customerId => ({
  type: REMOVE_CART,
  payload: Promise.resolve(customerId)
});

export const setComment = (customerId, comment) => ({
  type: SET_COMMENT,
  customerId,
  payload: comment
});

export const submitCart = customerId => (dispatch, getState) => {
  const state = getState();
  if (!state.app.get('online')) {
    dispatch({
      type: SET_CART_PENDING,
      payload: customerId
    });
    return Promise.resolve();
  }

  const cart = findCustomerCart(state.cart, customerId);
  if (!cart) {
    return Promise.reject();
  }
  return dispatch({
    type: SUBMIT_CART,
    customerId,
    payload: api.post('/order', cart.toJS())
  });
};

export const addItem = (customerId, productType, product, quantity) => ({
  type: ADD_ITEM,
  payload: {
    customerId,
    productType,
    product,
    quantity,
  }
});

export const removeItem = (customerId, originalObjectId) => ({
  type: REMOVE_ITEM,
  payload: {
    customerId,
    originalObjectId
  }
});

// ------------------------------------
// Default State
// ------------------------------------
const State = new Immutable.Record({
  isSyncing: false,
  hasSynced: false,
  syncError: null,
  carts: Immutable.fromJS(getStorageItem('carts', []))
});

// ------------------------------------
// Action Handlers
// ------------------------------------
//

const ACTION_HANDLERS = {
  [SET_COMMENT]: (state, { customerId, payload }) => {
    const customerIndex = findCustomerCartIndex(state, customerId);
    if (customerIndex === -1) {
      return state;
    }
    return state
      .setIn(['carts', customerIndex, 'comment'], payload);
  },
  [SET_CART_PENDING]: (state, { payload }) => {
    const customerIndex = findCustomerCartIndex(state, payload);
    if (customerIndex === -1) {
      return state;
    }
    return state.setIn(['carts', customerIndex, 'pending'], true);
  },
  [SUBMIT_CART]: (state, { status, customerId, payload }) => {
    const customerIndex = findCustomerCartIndex(state, customerId);
    if (customerIndex === -1) {
      return state;
    }
    switch (status) {
      case 'pending': {
        return state
          .setIn(['carts', customerIndex, 'inProgress'], true);
      }
      case 'success': {
        return state
          .deleteIn(['carts', customerIndex]);
      }
      case 'error': {
        return state
          .setIn(['carts', customerIndex, 'inProgress'], false)
          .setIn(['carts', customerIndex, 'error'], payload);
      }
    }
    return state;
  },
  [REMOVE_CART]: (state, { status, payload }) => {
    switch (status) {
      case 'success': {
        const customerIndex = findCustomerCartIndex(state, payload);
        if (customerIndex === -1) {
          return state;
        }
        return state.deleteIn(['carts', customerIndex]);
      }
    }
    return state;
  },
  [ADD_ITEM]: (state, { payload }) => {
    const {
      customerId,
      productType,
      product,
      quantity,
    } = { ...payload };

    const newItem = {
      productTypeId: productType.get('id'),
      originalObjectId: product.get('id'),
      text: product.get('text'),
      quantity
    };
    if (!newItem.originalObjectId) {
      newItem.originalObjectId = product.get('originalObjectId') || product.get('imageId');
      newItem.svgImageId = product.get('svgImageId');
    }

    // new item for a new customer's cart
    const customerIndex = findCustomerCartIndex(state, customerId);
    if (customerIndex === -1) {
      return state.set('carts', state.get('carts').push(Immutable.fromJS({
        customerId,
        created: moment().format(),
        items: [
          newItem
        ],
      })));
    }

    const cartItems = state.getIn(['carts', customerIndex, 'items']);
    const itemIndex = cartItems.findIndex(i => i.get('originalObjectId') === newItem.originalObjectId);
    if (itemIndex === -1) {
      return state.setIn(['carts', customerIndex, 'items'], cartItems.push(Immutable.fromJS(newItem)));
    }

    const item = cartItems.get(itemIndex);
    if (item) {
      return state
        .setIn(['carts', customerIndex, 'items', itemIndex, 'quantity'], item.get('quantity') + quantity);
    }
    return state;
  },
  [REMOVE_ITEM]: (state, { payload }) => {
    const customerIndex = findCustomerCartIndex(state, payload.customerId);
    if (customerIndex === -1) {
      return state;
    }

    const cartItems = state.getIn(['carts', customerIndex, 'items']);
    const itemIndex = cartItems.findIndex(i => i.get('originalObjectId') === payload.originalObjectId);
    if (itemIndex === -1) {
      return state;
    }

    return state.deleteIn(['carts', customerIndex, 'items', itemIndex]);
  },
};

const persistState = (state) => {
  if (state) {
    setStorageItem('carts', state.get('carts').toJS());
  }
  return state;
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  // if (handler) {
  //   state = state.set('token', Immutable.fromJS(JSON.parse(window.localStorage.getItem('authState')) || {}));
  // }
  return handler ? persistState(handler(state, action)) : state;
}
