/* eslint-disable jsx-a11y/label-has-for,react/forbid-prop-types,react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Immutable from 'immutable';
import Select from './Select';

export const getSelectCompatArray = (imap, valueKey) => {
  if (!imap) {
    return [];
  }
  valueKey = valueKey || 'id';
  return imap.map(item => item.get(valueKey));
};

export const addOptionsToSchema = (schema, options) => {
  if (!options) {
    return schema;
  }
  return schema.set('enum', getSelectCompatArray(options)).set('enum_titles', getSelectCompatArray(options, 'title'));
};

class SchemaInput extends React.Component {
  static contextTypes = {
    ajv: PropTypes.object,
    _reduxForm: PropTypes.object
  };

  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    path: PropTypes.string, // deprecated for name
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    schema: PropTypes.instanceOf(Immutable.Map).isRequired,
    parse: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    placeholder: PropTypes.string,
    note: PropTypes.string,
    disabled: PropTypes.bool,
    inputType: PropTypes.string
  };

  static defaultProps = {
    className: '',
    disabled: false,
    label: null,
    placeholder: null,
    note: null,
    inputType: null,
    path: null // deprecated for name
  };

  getClassNames(extraClasses, meta) {
    const classes = ['schema-field'];

    if (this.props.disabled) {
      classes.push('schema-field-disabled');
    }
    if (this.props.className) {
      classes.push(this.props.className);
    }
    if ((meta.touched || meta.dirty) && meta.invalid) {
      classes.push('has-error');
    } else if ((meta.touched || meta.dirty) && meta.valid) {
      classes.push('has-success');
    }
    return classes.concat(extraClasses.split(' ')).join(' ');
  }

  messages = {
    maximum: 'Te hoog aantal.',
    required: 'Dit veld is verplicht.',
    type: 'Voer een geldig waarde in.',
    pattern: 'Voer een geldig waarde in.',
    format: 'Voer een geldig waarde in.',
    formatEmail: 'Voer een geldig e-mailadres in.',
  };

  normalizeBooleanValue = v => !!v;

  normalizeIntegerValue = (v) => {
    if (v === undefined) {
      return v;
    }
    const _v = parseInt(v, 10);
    if (Number.isNaN(_v)) {
      return v;
    }
    return _v;
  };

  normalizeNumberValue = (v) => {
    if (v === undefined) {
      return v;
    }
    const _v = parseInt(v, 10);
    if (Number.isNaN(_v)) {
      return v;
    }
    return _v;
  };

  normalizeUriValue = (v) => {
    if (v === undefined) {
      return v;
    }
    if (!v) {
      return v;
    }
    if (/^\s*(h$|ht$|htt$|https?$|https?:|https?:\/|https?:\/\/)/.test(v)) {
      return v.trim();
    }
    return v.replace(/^/, 'http://').trim();
  };

  renderRadio(value, title) {
    return (
      <label key={value}>
        <Field name={this.props.path} component="input" type="radio" value={value} parse={this.props.parse} />
        { title }
      </label>
    );
  }

  // eslint-disable-next-line no-unused-vars
  renderRadioGroup({ input, meta, schema }) {
    if (!schema.get('enum')) {
      return this.renderRadio(this.props.value, this.props.children || schema.get('title'));
    }
    return (
      <div className={this.getClassNames('schema-field-ui-radiogroup')}>
        {
          schema.get('enum').map(v => this.renderRadio(v, schema.getIn(['enum_titles', v], v)))
        }
      </div>
    );
  }

  renderSelect({
    input, meta, schema, options
  }) {
    const id = `form-input-${this.props.name || this.props.path}`;
    const note = schema.get('note', null) || this.props.note;
    return (
      <Select
        field={{ input, meta, schema: addOptionsToSchema(schema, options) }}
        id={id}
        className="form-control"
        aria-describedby={note ? `${id}-note` : null}
      />
    );
  }

  renderLabel({ schema }) {
    if (this.props.label === null) {
      return null;
    }
    const label = this.props.label === true ? null : this.props.label;
    const title = this.props.children || schema.get('title') || label || this.props.placeholder || null;
    const note = null; // schema.get('note', null) || this.props.note;

    if (!title && !note) {
      return null;
    }

    const id = `form-input-${this.props.name || this.props.path}`;
    if (!note) {
      return (
        <label htmlFor={id}>
          {`${title}${schema.get('required') ? ' *' : ''}`}
        </label>
      );
    }
    return (
      <label htmlFor={id}>
        {`${title}${schema.get('required') ? ' *' : ''}`}<br />
        <small className="form-text text-muted">
          {`${note}`}
        </small>
      </label>
    );
  }

  renderInput({
    input, meta, schema, ...rest
  }) {
    const id = `form-input-${this.props.name || this.props.path}`;
    const inputType = schema.get('type') === 'number' ? 'number' : (this.props.inputType || 'text');

    // default
    const hasError = meta.touched && meta.error;

    const classNames = [];
    if (rest.type !== 'checkbox') {
      classNames.push('form-control');
    }
    if (hasError) {
      classNames.push('is-invalid');
    }

    const note = schema.get('note', null) || rest.note;
    const noteId = `${id}-note`;

    return (schema.get('inputType') || this.props.inputType || rest.type) === 'textarea' ? (
      <textarea
        {...input}
        required={schema.get('required')}
        placeholder={schema.get('description') || this.props.placeholder}
        minLength={schema.get('minLength')}
        maxLength={schema.get('maxLength')}
        className={classNames.join(' ')}
        aria-describedby={note ? noteId : null}
        id={id}
        disabled={this.props.disabled}
      />
    ) : (
      <input
        {...input}
        type={schema.get('format') === 'email' ? 'email' : inputType}
        required={schema.get('required')}
        aria-describedby={note ? noteId : null}

        minLength={schema.get('minLength')}
        maxLength={schema.get('maxLength')}

        min={schema.get('minimum')}
        max={schema.get('maximum')}
        pattern={schema.get('pattern')}
        className={classNames.join(' ')}
        id={id}
        disabled={this.props.disabled}

        placeholder={schema.get('description', this.props.placeholder)}
      />
    );
  }

  renderNote = (note, noteId) => note && (
    <small id={noteId} className="form-text text-muted">
      {note}
    </small>
  );

  renderError = (meta, schema) => {
    const isRequired = meta.error === 'required'
      || (meta.error === 'minLength' && schema.get('minLength') === 1 && schema.get('required'))
      || (meta.error === 'enum' && schema.get('required'));

    return meta.error && (meta.touched || meta.dirty) && meta.invalid && (
      <div className="invalid-feedback">
        {
          this.messages[
            isRequired ? 'required'
              : meta.error + (meta.error === 'format' && schema.get('format') === 'email' ? 'Email' : '')
          ] || meta.error
        }
        &nbsp;
        {
          !isRequired ? schema.get('description', this.props.placeholder) : null
        }
      </div>
    );
  };

  renderInputType = (props) => {
    const { schema } = props;
    const inputType = schema.get('inputType') || this.props.inputType;
    // if (inputType === 'image') {
    //   return <ImageFileUploadField {...props} />;
    // }
    // if (inputType === 'recaptcha') {
    //   return <ReCaptchaField {...props} />;
    // }
    if (inputType === 'radio') {
      return this.renderRadioGroup(props);
    }
    if (schema.get('enum')) {
      return this.renderSelect(props);
    }
    return this.renderInput(props);
  };

  renderSchemaInput = (props) => {
    const hasError = props.meta.touched && props.meta.error;
    if (props.type === 'hidden') {
      return <input {...props.input} type="hidden" />;
    }

    const id = `form-input-${this.props.name || this.props.path}`;
    const note = props.schema.get('note', null) || props.note;
    const noteId = `${id}-note`;

    return (
      <div className={`${props.className} mb-2 ${hasError ? 'has-danger' : ''}`}>
        { this.renderLabel(props) }
        { this.renderInputType(props) }
        { hasError && (
          <div className="invalid-feedback">
            { props.meta.error }
          </div>
        ) }
        {
          this.renderNote(note, noteId)
        }
      </div>
    );
  };

  render() {
    if (!this.props.schema) {
      return null;
    }

    const fieldName = this.props.name || this.props.path || '';
    const path = `properties.${fieldName.split('.').join('.properties.')}`.split('.');
    let propertySchema = this.props.schema.getIn(path);
    if (!propertySchema) {
      return null;
    }
    const property = path.slice().pop();

    const parentPath = path.slice(0, path.length - 2); // back to parent
    const parentSchema = this.props.schema.getIn(parentPath);
    if (parentSchema) {
      const parentSchemaRequired = parentSchema.get('required');
      if (parentSchemaRequired && parentSchemaRequired.contains(property)) {
        propertySchema = propertySchema.set('required', true);
      }
    }

    const type = propertySchema.get('type', 'string');
    const format = propertySchema.get('format');

    let normalize = null;
    if (type === 'boolean') {
      normalize = this.normalizeBooleanValue;
    }
    if (type === 'integer') {
      normalize = this.normalizeIntegerValue;
    }
    if (type === 'number') {
      normalize = this.normalizeNumberValue;
    }
    if (format === 'uri') {
      normalize = this.normalizeUriValue;
    }

    return (
      <Field
        {...this.props}
        name={fieldName}
        schema={propertySchema}
        normalize={normalize}
        component={this.renderSchemaInput}
      />
    );
  }
}

export default SchemaInput;
