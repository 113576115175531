import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './Modal.css';
import Icon from './Icon';

const __DEV__ = process.env.NODE_ENV !== 'production';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 9123456
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 91234567,
    minWidth: 300,
    padding: 0,
    borderRadius: 0,
  }
};

class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.node,
    children: PropTypes.node,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    isOpen: false,
    title: null,
    children: null,
    onCancel: null,
    onSubmit: null,
  };

  componentWillMount() {
    // window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    // window.removeEventListener('keydown', this.onKeyDown);
  }

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onSubmit = () => {
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  onKeyDown = (e) => {
    if (__DEV__) {

      console.log('onKeyDown', e.keyCode, e);
    }
    if (e.keyCode === 27) { // Escape
      e.preventDefault();
      this.onCancel();
    }
    if (e.keyCode === 13) { // Enter
      e.preventDefault();
      this.onSubmit();
    }
  };

  render() {
    return (
      <ReactModal shouldCloseOnEsc isOpen={this.props.isOpen} ariaHideApp={false} style={customStyles}>
        { this.props.title && (
          <div className="Modal__Title d-flex flex-row justify-content-between align-items-center">
            <div>{this.props.title}</div>
            <Icon icon="times" onClick={this.onCancel} />
          </div>
        )}
        <div className="Modal__Content">
          { this.props.children }
        </div>
      </ReactModal>
    );
  }
}

export default Modal;
