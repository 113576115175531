import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';
import { reducer as reduxForm } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';
import { reducer as toastr } from 'react-redux-toastr';

import app from './modules/app';
import auth from './modules/auth';
import customer from './modules/customer';
import product from './modules/product';
import cart from './modules/cart';

export const makeRootReducer = (asyncReducers, reduxFormReducerPlugins) => combineReducers({
  ...asyncReducers,
  auth,
  app,
  customer,
  product,
  cart,
  toastr,
  form: reduxForm.plugin({ ...reduxFormReducerPlugins }),
  notifications,
  router,
});

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers, store.reduxFormReducerPlugins));
};

const reduxFormReducerPlugins = {};
export const injectReduxFormReducerPlugin = (store, { form, reducer }) => {
  if (!reduxFormReducerPlugins[form]) {
    reduxFormReducerPlugins[form] = [reducer];
  } else {
    reduxFormReducerPlugins[form].push(reducer);
  }
  store.reduxFormReducerPlugins[form] = (state, action) => {
    reduxFormReducerPlugins[form].forEach((r) => {
      state = r(state, action);
    });
    return state;
  };
  store.replaceReducer(makeRootReducer(store.asyncReducers, store.reduxFormReducerPlugins));
};

export default makeRootReducer;
