import isObject from 'lodash/isObject';

/**
 * Flatten a deep object into a one level object with it’s path as key
 *
 * @see https://github.com/hughsk/flat/issues/52
 *
 * @param  {object} object - The object to be flattened
 * @param  {object} d - The delimiter (defaults to dot)
 *
 * @return {object}        - The resulting flat object
 */
export const flattenObject = (object, d = '.') => {
  const r = {};
  (function f(o, p) {
    // eslint-disable-next-line no-return-assign
    Object.keys(o).forEach(k => (o[k] && typeof o[k] === 'object' ? f(o[k], p ? `${p}${d}${k}` : k) : (r[p ? `${p}${d}${k}` : k] = o[k])));
  }(object));
  return r;
};

/**
 *
 * @param arr
 * @param byKey
 */
export const arrayToObjectByKey = (arr, byKey) => arr.reduce((result, item) => {
  result[item[!byKey ? Object.keys(item)[0] : Object.keys(item).filter(k => k === byKey)[0]]] = item;
  return result;
}, {});

const rootKeywords = ['required', 'dependencies', 'additionalProperties', 'oneOf', 'validator'];
export const ajvErrorMapperCreator = (errors, t) => (err) => {
  let nibble = errors;
  const path = err.dataPath.replace(/\[([0-9]+)\]/m, '.$1').split('.').slice(1);

  // get property
  let property = null;
  let message = null;

  if (rootKeywords.indexOf(err.keyword) === -1) {
    property = path.pop();
  }

  // build path
  path.forEach((part) => {
    // eslint-disable-next-line no-multi-assign
    nibble = nibble[part] = !nibble[part] ? {} : nibble[part];
  });

  if (err.keyword === 'required' || err.keyword === 'dependencies' || err.keyword === 'enum') {
    message = 'requiredfield';
    if (err.keyword !== 'enum') {
      property = err.params.missingProperty;
    }
  }

  if (err.keyword === 'oneOf') {
    if (!isObject(nibble)) {
      nibble = {};
    }
    if (Object.keys(nibble).length > 0) {
      return null;
    }
    property = 'renderOneOf';
    message = 'requiredfield';
  }

  if (err.keyword === 'minLength' && err.params.limit === 1) {
    message = 'requiredfield';
  }

  if (err.keyword === 'validator') {
    // eslint-disable-next-line prefer-destructuring
    property = err.params.property;
    // eslint-disable-next-line prefer-destructuring
    message = err.message;
  }

  // eslint-disable-next-line no-multi-assign
  nibble = nibble[property] = t ? t(message || err.keyword, err.params) : err.keyword;
  return null;
};

/**
 *
 * @param errors
 */
export const reduxFormOnSubmitFailScrollToFirstError = (errors) => {
  if (!errors) {
    return;
  }
  // # Focus on first error

  // When Immutable:
  // The errors are _not_ in `REGISTER_FIELD` order so we cant just "use" the first one..
  // (possibly requires an ordered list reviver when getting errors?)

  // if (process.env.NODE_ENV === 'development') {
  //
  //   console.error('onSubmitFail.errors', errors);
  // }

  // so we try to get the first error element in another way:
  // We do this using a DOM selector of all the fields that have error'ed and use `querySelector` which
  // Returns the first Element within the document that matches the specified selector, or group of selectors.
  const errorEl = document.querySelector(Object.keys(flattenObject(errors)).map(fieldName => `[name="${fieldName}"]`).join(','));
  // if (process.env.NODE_ENV === 'development') {
  //
  //   console.error('onSubmitFail.errorEl', errorEl);
  // }
  if (errorEl && errorEl.focus) { // npe
    if (errorEl.getAttribute('type') === 'hidden') {
      if (errorEl.nextSibling) {
        errorEl.nextSibling.scrollIntoView();
      } else {
        errorEl.parentNode.scrollIntoView();
      }
    } else {
      if (errorEl.scrollIntoView) {
        // errorEl.scrollIntoView(); // fails as the site has a fixed header
      }
      errorEl.focus();
    }
  }
};

export default { arrayToObjectByKey, ajvErrorMapperCreator };
