import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { replace as routeReplace } from 'react-router-redux';
import { Link } from 'react-router-dom';
import { reduxForm, formPropTypes } from 'redux-form';
import moment from 'moment';

import Input from '../../component/Form/Input';
import Logo from '../../component/Logo';
import { login, hydrateProfile } from '../../store/modules/auth';
import Icon from '../../component/Icon';

const AuthStatusContainer = ({ message, status }) => (
  <div className="row">
    <div className="col-12">
      <div className={`alert alert-${status}`}>
        { message }
      </div>
    </div>
  </div>
);
AuthStatusContainer.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['info', 'success', 'warning', 'danger']).isRequired,
};

class LoginContainer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    ...formPropTypes,
    login: PropTypes.func.isRequired,
    hydrateProfile: PropTypes.func.isRequired,
    isLoggedOut: PropTypes.bool.isRequired,
    isOnline: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    authError: PropTypes.any
  };

  static defaultProps = {
    authError: null,
  };

  componentWillMount() {
    if (this.props.token && !this.props.isAuthExpired && this.props.location && this.props.location.state) {
      this.props.routeReplace(this.props.location.state.from.pathname || '/');
    }
  }

  componentWillReceiveProps(props) {
    if (props.token && !props.isAuthExpired) {
      this.props.routeReplace((props.location && props.location.state && props.location.state.from.pathname) || '/');
    }
  }

  onSubmit = data => this.props.login(data).then(() => this.props.hydrateProfile());

  renderAuthStatus() {
    const { t, authError, token } = this.props;
    if (authError) {
      const message = (authError.body && authError.body.message) || authError.message;
      return (
        <AuthStatusContainer message={message} status="danger" />
      );
    }

    if (token && this.props.isAuthExpired) {
      return (
        <AuthStatusContainer message={t('Your session has expired')} status="danger" />
      );
    }

    if (token && this.props.isLoggedOut) {
      return (
        <AuthStatusContainer message={t('You have been logged out')} status="success" />
      );
    }

    return null;
  }

  renderOffline = () => (
    <div className="modal-container">
      <Logo />
      <div>
        <div className="alert alert-danger">
          {this.props.t('You are offline and have never logged in')}
        </div>
        <div>
          {this.props.t('authofflinedescription')}
        </div>
      </div>
    </div>
  );

  render() {
    if (!this.props.isOnline) {
      return this.renderOffline();
    }
    const { t } = this.props;

    return (
      <form className="modal-container" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>

        <Logo />

        {this.renderAuthStatus()}

        <div className="row form-group">
          <div className="col-12">
            <Input type="text" name="login" placeholder={t('Username')} autoComplete="username" />
          </div>
        </div>

        <div className="row form-group">
          <div className="col-12">
            <Input type="password" inputType="password" name="password" placeholder={t('Password')} autoComplete="current-password" />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button type="submit" className="btn btn-primary col-12">
              {
                this.props.submitting ? (
                  <Icon spin icon="circle-notch" />
                ) : t('Login')
              }
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center mt-3">
            <Link to="/auth/reset">{t('Forgot password?')}</Link>
          </div>
        </div>

      </form>
    );
  }
}

export default translate(['common', 'auth'])(connect((state) => {
  const expires = moment(state.auth.getIn(['data', 'expires'], moment().subtract(1, 'day')));
  return {
    isOnline: state.app.get('online'),
    authError: state.auth.get('error'),
    token: state.auth.getIn(['data', 'token']),
    isAuthExpired: !expires.isAfter(moment().add(1, 'minute')),
    isLoggedOut: state.auth.get('isLoggedOut')
  };
}, {
  login,
  hydrateProfile,
  routeReplace
})(reduxForm({
  form: 'login',
  validate: (data, props) => {
    const errors = {};

    if (!data.login) {
      errors.login = props.t('This field is required');
    }

    if (!data.password) {
      errors.password = props.t('This field is required');
    }

    return errors;
  }
})(LoginContainer)));
