import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Immutable from 'immutable';
import { createSelector } from 'reselect';

import CartItem from './CartItem';

// import CartItem from './CartItem';

class GroupedCartItems extends Component {
  static propTypes = {
    // productTypeId: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    productType: PropTypes.instanceOf(Immutable.Map).isRequired,
    group: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="color-primary font-bold">{t(this.props.productType.get('code'))}</div>
        {this.props.group.get('items').valueSeq().map(item =>
          <CartItem key={item.get('originalObjectId')} {... this.props} item={item} />)}
      </div>
    );
  }
}

const selectProductTypes = createSelector([
  state => state.product
], state => state.get('data'));

const selectProductType = createSelector([
  state => selectProductTypes(state),
  (state, group) => group.get('id'),
], (productTypes, productTypeId) => productTypes.find(productType => productType.get('id') === productTypeId));

export default translate()(connect((state, props) => ({
  productType: selectProductType(state, props.group)
}), {
})(GroupedCartItems));
