import React from 'react';
import PropTypes from 'prop-types';

import Spinner from './Spinner';

import './Loader.css';

const Loader = ({
  message, children, messagePosition, color, backgroundColor, spinnerSize, className, strokeWidth
}) => {
  const loaderClasses = [
    'Loader',
    'd-flex',
    className || '',
    'flex-column justify-content-center align-items-center'
  ];
  const containerClasses = [
    'Loader__Container',
    'd-flex'
  ];
  const messageClasses = [
    'Loader__Container__Message',
  ];
  switch (messagePosition) {
    case 'top':
      containerClasses.push('flex-column');
      containerClasses.push('flex-column-reverse');
      containerClasses.push('align-items-center');
      messageClasses.push('mb-3');
      break;
    case 'right':
      containerClasses.push('flex-row');
      containerClasses.push('align-items-center');
      messageClasses.push('ml-3');
      break;
    case 'bottom':
      containerClasses.push('flex-column');
      containerClasses.push('align-items-center');
      messageClasses.push('mt-3');
      break;
    case 'left':
      containerClasses.push('flex-row');
      containerClasses.push('flex-row-reverse');
      containerClasses.push('align-items-center');
      messageClasses.push('mr-3');
      break;
    default:
      break;
  }
  return (
    <div
      className={loaderClasses.join(' ')}
      style={{ backgroundColor }}
    >
      <div className={containerClasses.join(' ')} style={{ color }}>
        <Spinner
          color={color}
          style={{ maxWidth: spinnerSize, maxHeight: spinnerSize }}
          strokeWidth={strokeWidth}
        />
        <div className={messageClasses.join(' ')}>
          { children || message }
        </div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  message: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  messagePosition: PropTypes.oneOf(['top', 'right', 'bottom', 'right']),
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  spinnerSize: PropTypes.number,
  strokeWidth: PropTypes.number,
};

Loader.defaultProps = {
  className: null,
  message: null,
  children: null,
  messagePosition: 'right',
  color: 'gray',
  backgroundColor: 'transparent',
  spinnerSize: 30,
  strokeWidth: 6
};

export default Loader;
