import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { replace as routeReplace } from 'react-router-redux';

import Loader from '../../component/Loader';
import { logout } from '../../store/modules/auth';
import { setActiveLanguage } from '../../store/modules/app';

import './Logout.css';

class LogoutContainer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    routeReplace: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    setActiveLanguage: PropTypes.func.isRequired,
  };

  static defaultProps = {
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.logout().then(() => {
        this.props.setActiveLanguage();
        this.props.routeReplace('/');
      });
    }, 600);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="LogoutContainer modal-container d-flex flex-column align-items-center justify-content-center">
        <h2>{t('Logging out')}</h2>
        <div>
          <Loader />
        </div>
      </div>
    );
  }
}

export default translate(['common', 'auth'])(connect(() => ({
}), {
  logout,
  setActiveLanguage,
  routeReplace
})(LogoutContainer));
