import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import withNavigableContent from '../../component/withNavigableContent';
import EditProfileContainer from './Edit';

class Overview extends Component {
  static propTypes = {
  };

  render() {
    return (
      <EditProfileContainer />
    );
  }
}

export default translate()(connect((state, props) => ({
  title: props.t('Edit profile')
}))(withNavigableContent({
  from: '/'
})(Overview)));
