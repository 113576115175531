import Immutable from 'immutable';
import uuid from 'uuid';

import api from '../../inc/api';
import { getStorageItem, setStorageItem } from '../../inc/storage';
import { updateSyncStatus } from './app';
// import { SET_FILTER } from './customer';

// ------------------------------------
// Actions
// ------------------------------------

export const HYDRATE = '@app/product/HYDRATE';
export const SET_FILTER = '@app/product/SET_FILTER';

// ------------------------------------
// Action generators
// ------------------------------------
export const hydrate = () => (dispatch) => {
  const syncId = uuid();
  dispatch(updateSyncStatus(syncId, 'Product data ophalen...', false));
  return dispatch({
    type: HYDRATE,
    payload: api.get('/product').then((payload) => {
      dispatch(updateSyncStatus(syncId, 'Product data opgehaald.', true));
      return payload;
    })
  });
};

export const setFilter = filter => ({
  type: SET_FILTER,
  payload: filter
});

// ------------------------------------
// Default State
// ------------------------------------
const State = new Immutable.Record({
  isFetching: false,
  hasFetched: false,
  error: null,
  filter: Immutable.fromJS(getStorageItem('products.filter', {})),
  data: Immutable.fromJS(getStorageItem('products', []))
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [SET_FILTER]: (state, { payload }) => state
    .set('filter', payload),
  [HYDRATE]: (state, { status, payload }) => {
    switch (status) {
      case 'pending':
        return state
          .set('isFetching', true);
      case 'success':
        return state
          .set('isFetching', false)
          .set('error', null)
          .set('data', Immutable.fromJS(payload));
      case 'error':
        return state
          .set('isFetching', false)
          .set('error', payload);
      default:
        return state;
    }
  },
};

const persistState = (state) => {
  if (window.navigator.onLine) {
    setStorageItem('products', state.get('data').toJS());
  }
  setStorageItem('products.filter', state.get('filter').toJS());
  return state;
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  // if (handler) {
  //   state = state.set('token', Immutable.fromJS(JSON.parse(window.localStorage.getItem('authState')) || {}));
  // }
  return handler ? persistState(handler(state, action)) : state;
}
