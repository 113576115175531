import React, { Component } from 'react';
import { connect } from 'react-redux';

import Logo from '../../component/Logo';
import Loader from '../../component/Loader';
import InitialSyncStatus from './InitialSyncStatus';

class InitialSyncContainer extends Component {
  static propTypes = {
  };

  render() {
    return (
      <div className="modal-container">

        <Logo />
        <br />

        <div className="d-flex flex-column justify-content-center">
          <Loader spinnerSize={36} message="Eerste synchronisatie is bezig. Even geduld a.u.b." />
          <InitialSyncStatus />
        </div>

      </div>
    );
  }
}

export default connect(state => ({
  hasSynced: state.app.get('hasSynced'),
  isSyncing: state.app.get('isSyncing')
}), {
})(InitialSyncContainer);
