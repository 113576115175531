import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Immutable from 'immutable';
import Link from 'react-router-dom/Link';

import './ProfileMenu.css';
import DropDownMenu from '../../component/Menu/DropDownMenu';
import Avatar from '../../component/Avatar';
import Icon from '../../component/Icon';

class ProfileMenu extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    profile: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  static defaultProps = {
  };

  renderActiveMenuItem() {
    return (
      <div className="d-flex flex-row align-items-center">
        <Avatar profile={this.props.profile} />
        <div>
          &nbsp;
          {
            [
              this.props.profile.get('firstName'),
              this.props.profile.get('middleName'),
              this.props.profile.get('lastName'),
            ].filter(v => !!v).join(' ')
          }
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <DropDownMenu activeMenuItem={this.renderActiveMenuItem()}>
        <div className="d-flex flex-column flex-1">
          <div className="d-flex flex-row flex-1" style={{ padding: 20 }}>
            <Avatar profile={this.props.profile} size={48} />
            <div className="d-flex flex-column flex-1" style={{ marginLeft: 20, width: 320, minWidth: 320 }}>
              <strong>
                {
                  [
                    this.props.profile.get('firstName'),
                    this.props.profile.get('middleName'),
                    this.props.profile.get('lastName'),
                  ].filter(v => !!v).join(' ')
                }
              </strong>
              <div className="d-flex flex-row flex-1 justify-content-between">
                <Link to="/profile">
                  {t('Edit profile')}
                </Link>
                <Link to="/auth/logout">
                  {t('Logout')}
                  &nbsp;
                  <Icon icon="sign-out" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </DropDownMenu>
    );
  }
}

export default translate(['common', 'auth'])(connect(state => ({
  profile: state.auth.get('profile'),
}))(ProfileMenu));

