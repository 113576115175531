import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import Icon from '../../component/Icon';

class SyncStatusStatus extends Component {
  static propTypes = {
    syncStatus: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
  };

  render() {
    return (
      <div>
        {
          this.props.syncStatus.keySeq().map((key) => {
            const status = this.props.syncStatus.get(key);
            return (
              <div key={status.get('id')}>
                { status.get('title') }
                &nbsp;
                { status.get('status') ? (
                  <Icon icon="check" />
                ) : (
                  <Icon icon="sync-alt" spin />
                ) }
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default connect(state => ({
  isSyncing: state.app.get('isSyncing', false),
  syncStatus: state.app.get('syncStatus'),
  syncError: state.app.get('syncError', null),
}))(SyncStatusStatus);
