/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import isArray from 'lodash/isArray';
import Select from 'react-virtualized-select';

import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';

const EMPTY_LIST = Immutable.List();

export const SelectWrapper = (props) => {
  const { field, ...other } = props;
  const {
    meta, input, schema, ...rest
  } = field;

  const allProps = { // don't call things "props" ;-)
    ...rest,
    ...input,
    ...other
  };

  const enumTitles = schema.get('enum_titles', schema.getIn(['options', 'enum_titles'], EMPTY_LIST));

  const selectOptions = schema.get('enum').map((value, idx) => ({
    value,
    label: enumTitles.get(idx, value)
  })).sort((optionA, optionB) => (optionA.label > optionB.label ? 1 : -1)).toJS();

  allProps.multi = !!field.multiple;
  allProps.options = selectOptions;

  allProps.className = allProps.className || '';
  allProps.className += meta.touched ? ` ${meta.error ? 'is-invalid' : ''}` : '';

  allProps.value = input.value !== null && typeof input.value !== 'undefined' ? input.value : null;

  allProps.onBlur = () => input.onBlur(field.input.value);

  allProps.clearable = false;
  allProps.required = rest.required === 'required';
  input.autocomplete = 'nope'; // todo custom renderer with own "input" tag which does disable autocomplete...

  allProps.onChange = (value) => {
    if (isArray(value)) {
      value = value.map(item => item.value);
    }
    if (value && (value.value || value.value === '')) {
      // eslint-disable-next-line prefer-destructuring
      value = value.value;
    }
    if (rest.onChange) {
      rest.onChange(value);
    }
    input.onChange(value);
  };

  return (<Select {...allProps} />);
};

SelectWrapper.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  searchPromptText: PropTypes.string,
  loadingPlaceholder: PropTypes.string,
  noResultsText: PropTypes.string
};

SelectWrapper.defaultProps = {
  placeholder: 'Selecteer...',
  searchPromptText: 'Typen om te zoeken',
  loadingPlaceholder: 'Laden...',
  noResultsText: 'Geen resultaten gevonden'
};

export default SelectWrapper;
