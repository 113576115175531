import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import withRouter from 'react-router/withRouter';
import Route from 'react-router/Route';
import Switch from 'react-router/Switch';

import IndexContainer from './Index/Overview';

import ProfileContainer from './Profile/Overview';

import CustomerIndexContainer from './Customer/Index';

class RouteContainer extends Component {
  static propTypes = {
  };

  static defaultProps = {
  };

  render() {
    return (
      <Switch>
        <Route path="/" exact component={IndexContainer} />
        <Route path="/profile" exact component={ProfileContainer} />
        <Route path="/customer" component={CustomerIndexContainer} />
        <Route path="/cart" exact component={ProfileContainer} />
      </Switch>
    );
  }
}

export default withRouter(RouteContainer);
