
export const getStorageItem = (key, defaultValue) => {
  try {
    const data = window.localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
  } catch (err) {
    /* ignore for now */
  }
  return defaultValue;
};

export const setStorageItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};
