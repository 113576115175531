import moment from 'moment-timezone/moment-timezone';
import 'moment/locale/nl';
import 'moment/locale/de';

moment.locale('nl');

//
// if (console.info) {
//
//   console.info(`Moment Locale: ${moment.locale()}`);
// }

// eslint-disable-next-line max-len
moment.tz.add('Europe/Amsterdam|AMT NST +0120 +0020 CEST CET|-j.w -1j.w -1k -k -20 -10|010101010101010101010101010101010101010101012323234545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545|-2aFcj.w 11b0 1iP0 11A0 1io0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1co0 1io0 1yo0 Pc0 1a00 1fA0 1Bc0 Mo0 1tc0 Uo0 1tA0 U00 1uo0 W00 1s00 VA0 1so0 Vc0 1sM0 UM0 1wo0 Rc0 1u00 Wo0 1rA0 W00 1s00 VA0 1sM0 UM0 1w00 fV0 BCX.w 1tA0 U00 1u00 Wo0 1sm0 601k WM0 1fA0 1cM0 1cM0 1cM0 16M0 1gMM0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|16e5');

// override to always use europe amsterdam
moment.tz.setDefault('Europe/Amsterdam');

if (console.info) {

  console.info('Moment TZ: Europe/Amsterdam');
}

export const isBeforeNow = current => current.isBefore(moment());

export const isAfterNow = current => current.isAfter(moment());

let now;
let oneOClock;
export const isTodayOrAfter = (current) => {
  if (!now) {
    now = moment();
  }
  if (!oneOClock) {
    oneOClock = now.clone().set('hour', 13).set('minute', 0).set('second', 0);
  }
  const nf = now.format('YYYY-MM-DD');
  if (current.isSame(nf)) {
    return now.isBefore(oneOClock);
  }
  return current.isSameOrAfter(nf);
};

export const FORMAT_DATE = 'dddd D MMMM YYYY';
export const FORMAT_TIME = 'H:mm';

export const renderDate = (dateTime, andTime = false) => {
  const m = moment(dateTime);
  if (!andTime) {
    return m.format(FORMAT_DATE);
  }
  return `${m.format(FORMAT_DATE)} om ${m.format(FORMAT_TIME)} uur`;
};

export const isToday = m => moment().format('YYYY-MM-DD') === m;
export const isTomorrow = m => moment().add(1, 'day').format('YYYY-MM-DD') === m;

export default moment;
