import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { translate } from 'react-i18next';
import { createSelector } from 'reselect';

import Icon from '../../../component/Icon';
import DropDownMenu from '../../../component/Menu/DropDownMenu';
import GroupedCartItems from './GroupedCartItems';

class Cart extends Component {
  static propTypes = {
    tree: PropTypes.instanceOf(Immutable.Map).isRequired,
    cartSize: PropTypes.number,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    cartSize: 0
  };

  static contextTypes = {
    customerId: PropTypes.number
  };

  state = {
    isOpen: false
  };

  onAfterOpen = () => {
    this.setState({ isOpen: true });
  };

  onAfterClose = () => {
    this.setState({ isOpen: false });
  };

  renderActiveItem = () => {
    const size = this.props.cartSize;
    const sizeText = `${size} item${size > 1 ? 's' : ''}`;
    return (
      <div className="d-flex flex-row justify-content-between btn btn-primary">
        <Icon icon="shopping-cart" className="mr-3" />
        <div className="mr-3">
          {this.props.t('shoppingcart')} ({sizeText})
        </div>
        <Icon
          icon={this.state.isOpen ? 'times' : 'chevron-down'}
          fixedWidth
          onClick={this.state.isOpen ? this.onAfterClose : null}
        />
      </div>
    );
  };

  render() {
    if (!this.props.cartSize) {
      return null;
    }
    const { t } = this.props;
    return (
      <DropDownMenu
        className="Cart"
        isOpen={this.state.isOpen}
        keepOpen
        onAfterOpen={this.onAfterOpen}
        onAfterClose={this.onAfterClose}
        activeMenuItem={this.renderActiveItem()}
        popoutBelow
      >
        <div className="m-3 font-small font-normal" style={{ minWidth: 300 }}>
          <div className="d-flex flex-row justify-content-between">
            <div>
              {t('description')}
            </div>
            <div>
              {t('quantity')}
            </div>
          </div>
          <div>
            {
              this.props.tree.valueSeq().map(group =>
                <GroupedCartItems key={group.get('id')} group={group} customerId={this.context.customerId} />)
            }
          </div>
          <div className="d-flex flex-column justify-content-center mt-3">
            <Link to={`/customer/${this.context.customerId}/cart`} onClick={this.onAfterClose} className="btn btn-primary">
              {t('continue')}
            </Link>
          </div>
        </div>
      </DropDownMenu>
    );
  }
}

const EMPTY_MAP = new Immutable.Map();

const selectCart = createSelector([
  state => state.cart,
  (state, customerId) => customerId,
], (state, customerId) => state.get('carts')
  .find(c => c.get('customerId') === customerId && !c.get('pending', false)));

const selectCartItems = createSelector([
  (state, customerId) => selectCart(state, customerId),
], cart => cart && cart.get('items'));

const selectCartSize = createSelector([
  (state, customerId) => selectCartItems(state, customerId)
], items => (items && items.size) || 0);

const selectItemTree = createSelector([
  (state, customerId) => selectCartItems(state, customerId),
], (items) => {
  if (!items) {
    return EMPTY_MAP;
  }
  return items.groupBy(x => x.get('productTypeId')).map((group, productTypeId) => new Immutable.Map({
    id: productTypeId,
    items: group
  }));
});

export default translate()(connect((state, props) => ({
  tree: selectItemTree(state, props.customerId),
  cartSize: selectCartSize(state, props.customerId),
}), {

})(Cart));
