import request from 'superagent';
// import moment from 'moment';
import Promise from 'bluebird';
import uuid from 'uuid';

import { getStore } from '../../store';
import { clearTokens } from '../../store/modules/auth';

// eslint-disable-next-line prefer-destructuring
const Request = request.Request;

Request.prototype.middleware = [];
request.use = (cb) => {
  Request.prototype.middleware.push(cb);
  return request;
};

/**
 * add base url
 *
 * this removes having to add the base url in every api request
 *
 * i.e. /someResource => /api/someResource
 *
 * or if it starts with http then just let it be
 *
 * i.e. http://external
 *
 * @todo move api to separate request (i.e. api.js)
 */
Request.prototype.addBaseUrl = function addBaseUrl() {
  if (this.url.indexOf('http') === 0) {
    return this;
  }
  // add base url.. means you can call this as "/resourceX" or "resourceX"
  this.url = `/gateway/api/agentApp/${this.url.replace(/^\//, '')}`;
  return this;
};

/**
 * Stub for test- and dev environment to rewrite the API call
 *
 * // @todo move out of app build; will substitute with a superagent mocker
 */
Request.prototype.reconfigureForEnvironment = function reconfigureForEnvironment() {
  // this is not yet implemented...
  // eslint-disable-next-line max-len
  // if (/\/locale$/.test(this.url)) {
  //   return this.mockResponse(200, {});
  // }
  // if (/\/env/.test(this.url)) {
  //   return this.mockResponse(200, {
  //     NODE_ENV: process.env.NODE_ENV
  //   });
  // }

  if (process.env.REACT_APP_MOCK_API) {
    return this.mockResponse();
  }
  return this;
};

/**
 * Mock responder
 *
 * TODO use superagent mocker
 */
Request.prototype.mockResponse = function mockResponse(status, body) {
  // parse args
  if (body === undefined) {
    body = status;
    status = undefined;
  }
  if (status === undefined && body instanceof Error) {
    status = 500;
  }

  // reset the _end to the original _end (if available)
  // this ensures we can return `this` to get standard functionality
  if (this._org_end) {
    this._end = this._org_end;
  }

  // when creating or updating post/put then just return the passed data
  if ((this.method === 'POST' || this.method === 'PUT') && !this.url.match(/auth\/login/)) {
    if (this._data) {
      body = this._data;
    }
    // TODO when posting/creating add an id..
    // TODO this entails knowledge of every endpoint's id..
    // TODO rewrite api spec to return base id always as "id"
    if (body && !body._id) {
      body._id = uuid();
    }
  }

  // if no body defined then retrieve body from static mocked data
  if (body === undefined) {
    const matches = this.url.match(/\/api\/([^?]+)$/);
    if (!matches[1]) {
      return this;
    }
    // eslint-disable-next-line global-require,import/no-dynamic-require
    body = require(`./mock/${matches[1]}.json`);
  }

  // if status not yet defined then 200 it
  if (status === undefined) {
    status = 200;
  }

  // create the response
  const response = { status, body };

  // overload the _end to return the above response
  this._org_end = this._end;
  this._end = function _end() {

    console.info('XHR mocked', this.method, this.url, response);
    return this.callback(null, response);
  };
  return this;
};

/**
 *
 */
Request.prototype.noCache = function noCache() {
  if (!this.url.startsWith('http')) {
    this.set('Cache-Control', 'no-cache');
  }
  return this;
};

/**
 *
 * @see https://github.com/KyleAMathews/superagent-bluebird-promise/blob/master/index.js
 *
 * Make superagent requests Promises/A+ conformant
 *
 * Call .then([onFulfilled], [onRejected]) to register callbacks
 *
 * @return {Promise}
 */

Request.prototype.then = function then(handler, errorHandler) {
  // const me = this;
  if (!this.promise) {
    this.promise = Promise.resolve().then((params) => {
      params = params || {};
      const state = getStore().getState();

      // const expires = state.auth.getIn(['data', 'expires']);
      const token = this._header['x-api-token'] || state.auth.getIn(['tokens', 'token']);

      params.token = token;
      this.set('X-API-TOKEN', token);
      this.set('X-Kmt-Language', state.app.getIn(['env', 'data', 'language'], 'nl').toUpperCase());

      this.set('Accept', 'application/json');
      this.query(params);
      this.addBaseUrl();
      this.noCache();
      this.reconfigureForEnvironment();
      // this.middleware.forEach(cb => cb.bind(me)(me));

      return new Promise((resolve, reject) => {
        this.end((err, res) => {
          // handle 403 errors when sending a token (i.e. was logged in and token is now invalid)
          if (res && (res.status === 401 || res.status === 403)) {
            getStore().dispatch(clearTokens());
            if (res.req.url.indexOf('/auth/login') === -1) {
              // document.location.reload();
            }
            err = new Error(err.message);
            err.status = res.status;
            err.body = res.body;
            err.res = res;
            reject(err);
            return;
          }

          if (err) {
            err = new Error(err.message);
            err.status = res && res.status;
            err.body = res && res.body;
            err.res = res;
            reject(err);
            return;
          }

          if (res.status >= 400) {
            const msg = `Cannot ${this.method} ${this.url} (${this.status})`;
            err = new Error(msg);
            err.status = res.status;
            err.body = res.body;
            err.res = res;
            reject(err);
            return;
          }

          // if ((this.method !== 'GET') && this.url.indexOf('/crud') >= 0 && !this.supressFeedback) {
          //   // show message?
          // }

          resolve(res.body);
        });
      });
    });
  }
  return this.promise.then(handler, errorHandler);
};

Request.prototype.token = function token(t) {
  this.set('X-API-TOKEN', t);
  return this;
};

export default request;
