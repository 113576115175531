import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Logo from '../../component/Logo';
// import LanguageSwitcher from './LanguageSwitcher';
import SyncStatus from './SyncStatus';
import EnvMenu from './EnvMenu';
import ProfileMenu from './ProfileMenu';

import './Header.css';

class Header extends Component {
  static propTypes = {
  };

  static defaultProps = {
  };

  render() {
    return (
      <div className="d-flex flex-row flex-no-shrink justify-content-between align-items-center Header">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Logo />
        </div>
        <div className="d-flex flex-row justify-content-end align-items-center Header__TopBar">
          <SyncStatus />
          <EnvMenu />
          <ProfileMenu />
        </div>
      </div>
    );
  }
}

export default connect(() => ({
}))(Header);
