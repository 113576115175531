import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { reduxForm, formPropTypes } from 'redux-form';
import { push as routePush } from 'react-router-redux';

import Logo from '../../component/Logo';
import Input from '../../component/Form/Input';
import { reset as resetPassword, resetResetResult } from '../../store/modules/auth';
import Icon from '../../component/Icon';

const AuthStatusContainer = ({ message, status }) => (
  <div className="row">
    <div className="col-12">
      <div className={`alert alert-${status}`}>
        { message }
      </div>
    </div>
  </div>
);
AuthStatusContainer.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['info', 'success', 'warning', 'danger']).isRequired,
};

class ResetContainer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    resetResetResult: PropTypes.func.isRequired,
    routePush: PropTypes.func.isRequired,
    ...formPropTypes
  };

  static defaultProps = {
  };

  onSubmit = data => this.props.resetPassword(data);

  onResetAccept = () => {
    this.props.resetResetResult();
    this.props.routePush('/');
  };
  renderAuthStatus() {
    if (this.props.resetError) {
      const message = (this.props.resetError.body && this.props.resetError.body.message)
        || this.props.resetError.message;
      return (
        <AuthStatusContainer message={message} status="danger" />
      );
    }
    return null;
  }

  renderResetResult = () => (
    <div className="modal-container">
      <Logo />
      <div>
        { this.props.t('resetdirections') }
      </div>
      <div>
        <button type="button" className="btn btn-primary" onClick={this.onResetAccept}>
          OK
        </button>
      </div>
    </div>
  );

  render() {
    if (this.props.resetResult === true) {
      return this.renderResetResult();
    }
    const { t } = this.props;

    return (
      <form className="modal-container" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>

        <Logo />

        {this.renderAuthStatus()}

        <div className="row form-group">
          <div className="col-12">
            <Input type="text" name="login" placeholder={t('Username')} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button type="submit" className="btn btn-primary col-12">
              {
                this.props.submitting ? (
                  <Icon spin icon="circle-notch" />
                ) : t('Request a password reset')
              }
            </button>

            <div className="row">
              <div className="col-12 d-flex justify-content-center align-items-center mt-3">
                <Link to="/">
                  <Icon icon="home" style={{ fontSize: 24 }} />
                </Link>
              </div>
            </div>

          </div>
        </div>

      </form>
    );
  }
}

export default translate(['common', 'auth'])(connect(state => ({
  resetResult: state.auth.get('resetResult', false)
}), {
  resetPassword,
  resetResetResult,
  routePush,
})(reduxForm({
  form: 'reset',
  validate: (data, props) => {
    const errors = {};
    if (!data.login) {
      errors.login = props.t('This is a required field');
    }
    return errors;
  },
})(ResetContainer)));
