import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { library } from '@fortawesome/fontawesome-svg-core';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart';
import { faHandPointRight } from '@fortawesome/pro-solid-svg-icons/faHandPointRight';
import { faCartPlus } from '@fortawesome/pro-solid-svg-icons/faCartPlus';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut';
import { faUndo } from '@fortawesome/pro-solid-svg-icons/faUndo';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faSave } from '@fortawesome/pro-solid-svg-icons/faSave';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons/faSyncAlt';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt';
import { faListAlt } from '@fortawesome/pro-solid-svg-icons/faListAlt';
import { faStroopwafel } from '@fortawesome/pro-solid-svg-icons/faStroopwafel';
import { faWifi } from '@fortawesome/pro-solid-svg-icons/faWifi';
import { faWifiSlash } from '@fortawesome/pro-solid-svg-icons/faWifiSlash';

import './Icon.css';

// TODO does this need to be put back in 5.3.1
// fontawesome.config = {
//   autoAddCss: true
// };

library.add(
  faSearch,
  faUser,
  faUserPlus,
  faCheck,
  faEdit,
  faTimes,
  faChevronDown,
  faShoppingCart,
  faCartPlus,
  faHandPointRight,
  faSignOut,
  faUndo,
  faSave,
  faCircleNotch,
  faHome,
  faExclamationTriangle,
  faExclamationCircle,
  faInfoCircle,
  faSyncAlt,
  faTrashAlt,
  faExternalLinkAlt,
  faListAlt,
  faWifi,
  faWifiSlash,
  faStroopwafel,
);

class Icon extends Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    fixedWidth: PropTypes.bool,
  };

  static defaultProps = {
    icon: '',
    className: '',
    onClick: null,
    fixedWidth: false,
  };

  render() {
    const {
      onClick, className, fixedWidth, ...passProps
    } = this.props;

    const props = {
      className: `Icon ${className || ''}`
    };

    if (onClick) {
      props.onClick = onClick;
      props.role = 'button';
      props.tabIndex = '-1';
    }

    if (fixedWidth) {
      if (!passProps.className) {
        passProps.className = '';
      }
      passProps.className = 'fa-fw';
    }
    return (
      <div {...props}>
        <FontAwesomeIcon {...passProps} />
      </div>
    );
  }
}

export default Icon;
