/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Link from 'react-router-dom/Link';
import { replace as routeReplace } from 'react-router-redux';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import debounce from 'lodash/debounce';

import Icon from '../../component/Icon';
import withCustomer from '../../component/withCustomer';
import GroupedCartItems from './catalog/GroupedCartItems';
import { removeCart, submitCart, setComment } from '../../store/modules/cart';
import OfflineChangesWarning from '../../component/OfflineChangesWarning';

class Cart extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    submitCart: PropTypes.func.isRequired,
    removeCart: PropTypes.func.isRequired,
    setComment: PropTypes.func.isRequired,
    routeReplace: PropTypes.func.isRequired,
    tree: PropTypes.instanceOf(Immutable.Map).isRequired,
    cart: PropTypes.instanceOf(Immutable.Map),
    customer: PropTypes.instanceOf(Immutable.Map).isRequired,
    customerId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    cart: null
  };

  state = {
    value: (this.props.cart && this.props.cart.get('comment', '')) || ''
  };

  onCancel = () => {
    this.props.removeCart(this.props.customerId).then(() => {
      this.redirectToCatalog();
    });
  };

  onSubmit = () => {
    this.props.submitCart(this.props.customerId).then(() => {
      this.redirectToCatalog();
    });
  };

  onChangeComment = (e) => {
    e.persist();
    this.setState({ value: e.target.value });
    this.onDebouncedChangeComment(e);
  };

  onDebouncedChangeComment = debounce((e) => {
    this.props.setComment(this.props.customerId, e.target.value);
  }, 50);

  redirectToCatalog = () => {
    this.props.routeReplace(`/customer/${this.props.customerId}/catalog`);
  };

  renderWrapper(children) {
    const { t } = this.props;
    return (
      <div className="d-flex flex-column flex-1 flex-no-shrink">
        <div className="d-flex flex-column flex-no-shrink justify-content-between box-shadow-bottom p-2 ">
          <div className="d-flex flex-column flex-md-row flex-no-shrink align-items-center justify-content-between">
            <div>
              <h2 className="mr-3 mb-0">
                { t('Cart') }
              </h2>
            </div>
            <div className="d-flex flex-row align-items-center">
              <Link to={`/customer/${this.props.customer.get('id')}/catalog`} className="btn btn-primary">
                <Icon icon="cart-plus" className="mr-3" />
                { t('Carry on shopping') }
              </Link>
            </div>
          </div>
        </div>
        { children }
      </div>
    );
  }

  render() {
    const { t } = this.props;

    if (!this.props.tree.size) {
      return this.renderWrapper((
        <div className="container justify-content-center align-items-center mt-3">
          <div className="alert alert-info">
            <h2>{t('Your cart is empty')}</h2>
            <Link to={`/customer/${this.props.customerId}/catalog`}>
              {t('Back to the catalogue')}
            </Link>
          </div>
        </div>
      ));
    }
    const { cart } = this.props;
    const inProgress = cart.get('inProgress', false);
    return this.renderWrapper((
      <div className="d-flex flex-1 flex-overflow-scroll p-2 pt-4">
        <div className="container">
          <div>
            {t('Overview')}
          </div>
          {
            this.props.tree.valueSeq().map(group =>
              <GroupedCartItems key={group.get('id')} group={group} customerId={this.props.customerId} />)
          }
          <div className="d-flex flex-column justify-content-between mt-3 mb-3">
            <label htmlFor="comment">
              {t('Comment')}
            </label>
            <textarea
              onChange={this.onChangeComment}
              name="comment"
              id="comment"
              className="form-control"
              style={{ width: '100%' }}
              placeholder={t('Comment')}
              value={this.state.value}
            />
          </div>

          <OfflineChangesWarning text="offlineorderdescription" />

          <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
            <button disabled={inProgress} className="btn btn-default mr-3" onClick={this.onCancel}>
              <Icon icon="trash-alt" className="mr-2" />
              {t('Delete order')}
            </button>
            <button disabled={inProgress} className="btn btn-primary" onClick={this.onSubmit}>
              <Icon icon={inProgress ? 'circle-notch' : 'save'} spin={inProgress} className="mr-2" />
              {t('Finalize order')}
            </button>
          </div>
        </div>
      </div>
    ));
  }
}

const EMPTY_MAP = new Immutable.Map();

const selectCart = createSelector([
  state => state.cart,
  (state, customerId) => customerId,
], (state, customerId) => state.get('carts')
  .find(c => c.get('customerId') === customerId && !c.get('pending', false)));

const selectCartItems = createSelector([
  (state, customerId) => selectCart(state, customerId),
], cart => cart && cart.get('items'));

const selectCartSize = createSelector([
  (state, customerId) => selectCartItems(state, customerId)
], items => (items && items.size) || 0);

const selectItemTree = createSelector([
  (state, customerId) => selectCartItems(state, customerId),
], (items) => {
  if (!items) {
    return EMPTY_MAP;
  }
  return items.groupBy(x => x.get('productTypeId')).map((group, productTypeId) => new Immutable.Map({
    id: productTypeId,
    items: group
  }));
});

export default translate()(withCustomer(connect((state, props) => ({
  cart: selectCart(state, props.customerId),
  tree: selectItemTree(state, props.customerId),
  cartSize: selectCartSize(state, props.customerId),
}), {
  removeCart,
  submitCart,
  setComment,
  routeReplace,
})(Cart)));
