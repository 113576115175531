/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
// import { Field } from 'redux-form';

import { addItem } from '../../../store/modules/cart';

import './ProductBox.css';
import Icon from '../../../component/Icon';
import { getProductImageUrl, price } from '../../../inc/util';

class ProductBox extends Component {
  static propTypes = {
    addItem: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
    productType: PropTypes.instanceOf(Immutable.Map).isRequired,
    product: PropTypes.instanceOf(Immutable.Map).isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    style: null,
  };

  static contextTypes = {
    customerId: PropTypes.number
  };

  state = {
    error: '',
    message: ''
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) { // VK_ENTER
      this.onAddToCart();
    }
  };

  onAddToCart = () => {
    const quantity = parseInt(this.input.value, 10);
    if (Number.isNaN(quantity)) {
      return;
    }
    const minimalOrderAmount = this.props.productType.get('minimalOrderAmount');
    if (quantity < minimalOrderAmount) {
      this.setState({ error: `Ongeldige besteleenheid (alleen per ${minimalOrderAmount} stuks)` });
      return;
    }
    this.setState({ error: '', message: '' });
    this.input.value = '';

    this.props.addItem(this.context.customerId, this.props.productType, this.props.product, quantity);

    this.setState({ message: 'Toegevoegd aan winkelwagen' });
  };

  setInputRef = (el) => {
    this.input = el;
  };

  render() {
    const { t, product, productType } = this.props;

    const fieldName = `product.${product.get('id', product.get('originalObjectId'))}`;

    return (
      <div
        className="ProductBox"
        style={this.props.style}
        data-id={product.get('id', product.get('originalObjectId'))}
        key={product.get('id', product.get('originalObjectId'))}
      >
        <div className="ProductBox__Title">
          { productType.get('id') === 'display' ? `Display ${t(product.get('text').replace('Display ', ''))}` : product.get('text')}
        </div>
        { product.get('description') && (
          <div className="ProductBox__Description">
            {product.get('description')}
          </div>
        )}
        { product.get('priceEx') >= 0 && (
          <div className="ProductBox__Price">
            { price(product.get('priceEx')) }
          </div>
        )}
        <div className="d-flex flex-no-shrink flex-column align-items-center">
          <div className="ProductBox__Image d-flex flex-no-shrink flex-column align-items-center mb-2">
            <img src={getProductImageUrl(product, productType)} alt="" />
          </div>
          <input type="hidden" id={`${fieldName}.productId`} name={`${fieldName}.productId`} value={product.get('id')} />

          <div className="ProductBox__Quantity d-flex flex-row mb-2">
            <label htmlFor={`${fieldName}.quantity`}>
              {t('quantity')}:
            </label>
            <input
              ref={this.setInputRef}
              type="number"
              id={`${fieldName}.quantity`}
              name={`${fieldName}.quantity`}
              onKeyDown={this.onKeyDown}
            />
          </div>

          <button type="button" className="btn btn-primary" onClick={this.onAddToCart}>
            <Icon icon="cart-plus" className="mr-2" />
            {t('addtocart')}
          </button>

          <div className="ProductBox__Feedback d-flex flex-row">
            { this.state.error && (
              <div className="font-small">
                <Icon icon="exclamation-circle" className="red" />
                &nbsp;
                { this.state.error }
              </div>
            )}

            { this.state.message && (
              <div className="font-small">
                <Icon icon="check" className="green" />
                &nbsp;
                { this.state.message }
              </div>
            )}
            &nbsp;
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(connect(null, {
  addItem
})(ProductBox));
