import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withRouter from 'react-router/withRouter';
import Link from 'react-router-dom/Link';

import './ProductType.css';

class ProductType extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    productType: PropTypes.instanceOf(Immutable.Map).isRequired,
    customer: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
  };

  render() {
    const {
      active, customer, productType, t
    } = this.props;
    return (
      <Link
        className={`pl-2 pr-2 Catalog__ProductType ${active ? ' Catalog__ProductType--active' : ''}`}
        to={`/customer/${customer.get('id')}/catalog/${productType.get('id')}`}
      >
        { t(productType.get('code')) }
      </Link>
    );
  }
}

export default withRouter(translate()(connect(() => ({
}))(ProductType)));
