import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';

class InitialSyncStatus extends Component {
  static propTypes = {
    syncStatus: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
  };

  render() {
    return (
      <div className="ml-5 pl-3">
        {
          this.props.syncStatus.sort((a, b) => {
            if (a.get('status')) {
              return 1;
            }
            if (b.get('status')) {
              return -1;
            }
            return 0;
          }).keySeq().map((key) => {
            const status = this.props.syncStatus.get(key);
            if (status.get('status')) {
              return (
                <div key={status.get('id')}>
                  &nbsp;
                </div>
              );
            }
            return (
              <div key={status.get('id')}>
                { status.get('title') }
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default connect(state => ({
  syncStatus: state.app.get('syncStatus'),
}), {
})(InitialSyncStatus);
