import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';

import rootReducer from './reducers';
import promiseMiddleware from './middleware/promise';

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();

// ======================================================
// Store Enhancers
// ======================================================
const enhancers = [];
if (process.env.NODE_ENV === 'development') {
  if (typeof window !== 'undefined' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

let store = null;
export const initStore = (initialState = {}) => {
  store = createStore(
    rootReducer(),
    initialState,
    compose(applyMiddleware(promiseMiddleware, thunkMiddleware, routerMiddleware(history)), ...enhancers)
  );
  store.asyncReducers = {};
  store.reduxFormReducerPlugins = {};

  store.history = history;

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer(store.asyncReducers, store.reduxFormReducerPlugins));
    });
  }
  return store;
};

export const getStore = () => store;

export default initStore;
