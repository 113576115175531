import Immutable from 'immutable';
import uuid from 'uuid';

import api from '../../inc/api';
import { getStorageItem, setStorageItem } from '../../inc/storage';
import { updateSyncStatus } from './app';

// ------------------------------------
// Actions
// ------------------------------------

export const HYDRATE = '@app/customer/HYDRATE';
export const CREATE = '@app/customer/CREATE';
export const UPDATE = '@app/customer/UPDATE';
export const SET_FILTER = '@app/customer/SET_FILTER';
export const SET_PENDING = '@app/customer/SET_PENDING';

// ------------------------------------
// Action generators
// ------------------------------------

export const hydrate = () => (dispatch) => {
  const syncId = uuid();
  dispatch(updateSyncStatus(syncId, 'Klant data ophalen...', false));
  return dispatch({
    type: HYDRATE,
    payload: api.get('/customer').then((payload) => {
      dispatch(updateSyncStatus(syncId, 'Klant data opgehaald.', true));
      return payload;
    })
  });
};

export const update = data => ({
  type: UPDATE,
  customerId: data.id,
  payload: data.id ? api.put(`/customer/${data.id}`, data) : api.post('/customer', data)
});

export const create = data => (dispatch, getState) => {
  const state = getState();
  if (!state.app.get('online')) {
    dispatch({
      type: SET_PENDING,
      customerId: uuid(),
      payload: data
    });
    return Promise.resolve();
  }
  return dispatch({
    type: CREATE,
    payload: api.post('/customer', data)
  });
};

export const setFilter = filter => ({
  type: SET_FILTER,
  payload: filter
});

// ------------------------------------
// Default State
// ------------------------------------
const State = new Immutable.Record({
  isFetching: false,
  hasFetched: false,
  error: null,
  data: Immutable.fromJS(getStorageItem('customers', [])),
  filter: Immutable.Map(),
  isCreating: false,
  createError: null,
  isUpdating: false,
  updateError: null
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const findCustomerIndex = (customers, customerId) => customers.findIndex(c => c.get('id') === customerId);

const ACTION_HANDLERS = {
  [SET_FILTER]: (state, { payload }) => state
    .set('filter', Immutable.fromJS(payload)),
  [HYDRATE]: (state, { status, payload }) => {
    switch (status) {
      case 'pending':
        return state
          .set('isFetching', true);
      case 'success':
        return state
          .set('isFetching', false)
          .set('error', null)
          .set('data', Immutable.fromJS(payload));
      case 'error':
        return state
          .set('isFetching', false)
          .set('error', payload);
      default:
        return state;
    }
  },
  [SET_PENDING]: (state, { customerId, payload }) => {
    const customers = state.get('data');
    const customerIndex = findCustomerIndex(customers, customerId);
    const data = Immutable.fromJS(payload).set('pending', true);
    if (customerIndex === -1) {
      return state
        .set('data', customers.push(data.set('id', customerId)));
    }
    return state
      .setIn(['data', customerIndex], Immutable.fromJS(payload));
  },
  [UPDATE]: (state, { status, payload }) => {
    switch (status) {
      case 'pending':
        return state
          .set('isUpdating', true);
      case 'success': {
        const customerId = payload.id;
        const customers = state.get('data');
        const customerIndex = findCustomerIndex(customers, customerId);
        if (customerIndex === -1) {
          return state
            .set('isUpdating', false)
            .set('updateError', null)
            .set('data', customers.push(Immutable.fromJS(payload)));
        }
        return state
          .set('isUpdating', false)
          .set('updateError', null)
          .setIn(['data', customerIndex], Immutable.fromJS(payload));
      }
      case 'error':
        return state
          .set('isUpdating', false)
          .set('error', payload);
      default:
        return state;
    }
  },
  [CREATE]: (state, { status, payload }) => {
    switch (status) {
      case 'pending':
        return state
          .set('isCreating', true);
      case 'success':
        return state
          .set('isCreating', false)
          .set('createError', null)
          .set('data', Immutable.fromJS(payload));
      case 'error':
        return state
          .set('isCreating', false)
          .set('error', payload);
      default:
        return state;
    }
  },
};

const persistState = (state) => {
  if (state) {
    setStorageItem('customers', state.get('data').toJS());
  }
  return state;
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  // if (handler) {
  //   state = state.set('token', Immutable.fromJS(JSON.parse(window.localStorage.getItem('authState')) || {}));
  // }
  return handler ? persistState(handler(state, action)) : state;
}
