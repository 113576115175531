import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import Icon from '../../../component/Icon';

import { removeItem } from '../../../store/modules/cart';

class CartItem extends Component {
  static propTypes = {
    removeItem: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
    item: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
  };

  onRemoveItem = () => {
    this.props.removeItem(
      this.props.customerId,
      this.props.item.get('originalObjectId')
    );
  };

  render() {
    const { item } = this.props;
    return (
      <div className="d-flex flex-row justify-content-between">
        <div className="mr-2">
          { item.get('text') }
        </div>
        <div className="d-flex flex-row">
          <div className="mr-2">
            { item.get('quantity') }
          </div>
          <Icon icon="times" className="color-primary" onClick={this.onRemoveItem} />
        </div>
      </div>
    );
  }
}

export default connect(null, {
  removeItem
})(CartItem);
