import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';

import { clearTokens } from '../store/modules/auth';

class AuthedRoute extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
    clearTokens: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoggedIn: false,
    location: null,
  };

  componentWillMount() {
    if (!this.props.isLoggedIn) {
      this.props.clearTokens();
    }
  }

  render() {
    if (!this.props.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: '/auth/login',
            state: { from: this.props.location }
          }}
        />
      );
    }
    return React.createElement(Route, this.props);
  }
}

export default connect((state) => {
  const token = state.auth.getIn(['data', 'token'], null);
  const expires = moment(state.auth.getIn(['data', 'expires'], moment().subtract(1, 'day')));
  return {
    isLoggedIn: !!token/* && expires.isAfter(moment()) */,
    shouldAuthUpdate: !expires.isAfter(moment().add(10, 'seconds'))
  };
}, {
  clearTokens
})(AuthedRoute);
