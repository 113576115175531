import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import './ProfileMenu.css';
import DropDownMenu from '../../component/Menu/DropDownMenu';
import Icon from '../../component/Icon';

class EnvMenu extends Component {
  static propTypes = {
    env: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  static defaultProps = {
  };

  renderActiveMenuItem() {
    const NODE_ENV = this.props.env.get('NODE_ENV');
    return (
      <div className="d-flex flex-row align-items-center">
        { NODE_ENV !== 'production' && (
          <div className="mr-3">
            { NODE_ENV.toUpperCase() }
          </div>
        ) }
      </div>
    );
  }

  render() {
    const NODE_ENV = this.props.env.get('NODE_ENV');
    if (!NODE_ENV) {
      return null;
    }
    return (
      <DropDownMenu activeMenuItem={this.renderActiveMenuItem()}>
        <div className="d-flex flex-column flex-1">
          <div className="d-flex flex-row flex-1" style={{ padding: 20 }}>
            <div className="d-flex flex-column flex-1" style={{ marginLeft: 20, width: 320, minWidth: 320 }}>
              <strong>
                { NODE_ENV.toUpperCase() }
              </strong>
              <div className="d-flex flex-row flex-1 justify-content-between">
                <a href={`${this.props.env.get('KMT_URL')}/kmt/`} target="_blank" rel="noopener noreferrer">
                  Ga naar de KMT
                  <Icon icon="external-link-alt" className="ml-2" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </DropDownMenu>
    );
  }
}

export default connect(state => ({
  env: state.app.getIn(['env', 'data']),
}))(EnvMenu);

