import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import hoistStatics from 'hoist-non-react-statics';

import './withNavigableContent.css';
import Icon from './Icon';

// import { Filter } from '../inc/propTypes';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withNavigableContentFactory(params) {
  return function withNavigableContent(WrappedComponent) {
    class WithNavigableContent extends React.Component {
      static propTypes = {
        title: PropTypes.string,
        from: PropTypes.string
      };

      static defaultProps = {
        title: params.title,
        from: params.from,
      };

      renderNavigation() {
        if (!this.props.title) {
          return null;
        }
        if (!this.props.from) {
          return (
            <div className="d-flex flex-column justify-content-center WithNavigableContent__Header">
              { this.props.title }
            </div>
          );
        }
        return (
          <div className="d-flex flex-no-shrink flex-row align-items-center justify-content-between WithNavigableContent__Header SelectedCustomerContainer__CustomerHeader ">
            <div>
              { this.props.title }
              &nbsp;
              <Link to={this.props.from}>
                <Icon icon="times" />
              </Link>
            </div>
          </div>
        );
      }

      render() {
        // Filter out extra props that are specific to this HOC and shouldn't be
        // passed through
        const { title, from, ...passThroughProps } = this.props;

        return (
          <div className="d-flex flex-1 flex-column">
            <div className="d-flex flex-column flex-1">
              { this.renderNavigation() }
              {
                React.createElement(
                  WrappedComponent,
                  { ...passThroughProps },
                )
              }
            </div>
          </div>
        );
      }
    }

    withNavigableContent.WrappedComponent = WrappedComponent;

    // WithUserContext.propTypes = {
    // };
    //
    // WithUserContext.contextTypes = {
    // };
    //
    // WithUserContext.childContextTypes = {
    // };

    withNavigableContent.displayName = `withNavigableContent(${getDisplayName(WrappedComponent)})`;

    return hoistStatics(WithNavigableContent, WrappedComponent);
  };
}

