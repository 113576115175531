import qs from 'locutus/php/url/http_build_query';

export const getProductImageUrl = (product, productType) => {
  const params = {
    id: product.get('imageId'),
    versionId: product.get('imageVersionId', 0),
    ttl: 604800
  };

  if (!params.id) {
    params.id = 182473;
    params.fltr = [
      'clr|95|FFFFFF'
    ];
  }

  switch (productType.get('id')) {
    case 'display':
      params.height = 250;
      params.width = 180;
      params.zc = 1;
      break;
    case 178510:
    case 240123:
    case 348387:
      params.height = 135;
      params.width = 200;
      params.zc = 1;
      break;

    case 240122:
      params.maxSize = 220;
      break;

    default:
      params.maxSize = 200;
  }
  return `/download/?${qs(params)}`;
};

export const round = (number, precision) => {
  // eslint-disable-next-line no-restricted-properties
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

// myNamespace.round(1234.5678, 1); // 1234.6
// myNamespace.round(1234.5678, -1); // 1230

export const price = (number, currency) => {
  if (!number || Number.isNaN(number)) {
    number = 0;
  }

  currency = currency === false ? '' : currency;

  if (currency !== '') {
    currency = currency || '€';
    currency += ' ';
  }
  return currency + number.toFixed(2).replace(/\./g, ',');
};

export default {
  round,
  price,
};
